import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import './ship-areas.scss'
import { useSpring, animated } from '@react-spring/web'
import { snakeCase } from 'lodash'

const ShipAreas = ({ navTitle, tiles, title, content, image, mobileImage }) => {
  const [readMore, setReadMore] = useState(false)
  const [activeTile, setActiveTile] = useState(null)
  const activeZoom = activeTile?.zoom
  const contentRef = useRef(null)
  const [style, setStyle] = useSpring(() => ({ height: '0px' }), [])
  useEffect(() => {
    if (contentRef?.current) {
      setStyle({
        height: `${readMore ? contentRef.current.offsetHeight : 0}px`,
      })
    }
  }, [readMore, contentRef, setStyle])

  useEffect(() => {
    const handleEscape = e => {
      if (e.code === 'Escape' && activeZoom) {
        changeTile(null)
      }
    }

    window.addEventListener('keydown', handleEscape)

    return () => window.removeEventListener('keydown', handleEscape)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeZoom])

  const handleTileClick = (e, tile) => {
    e.stopPropagation()
    changeTile(tile)
  }
  const handleImageClick = () => {
    if (activeZoom) {
      changeTile(null)
    }
  }

  function changeTile(newTile) {
    setActiveTile(newTile)
    setReadMore(false)
    setStyle({
      height: '0px',
    })
  }

  const activeImageStyles = activeZoom ? { transform: activeZoom } : {}

  const showContent = !activeZoom

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="ShipAreas">
      <div className="ship-sections">
        <div className={`header ${showContent ? 'active' : ''}`}>
          {title && <h1 className="title">{title}</h1>}
          {content && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        <div
          className={`image-container ${showContent ? '' : 'active'}`}
          onClick={handleImageClick}
        >
          <img
            className="wide-bg"
            src={image}
            alt={title}
            style={{ ...activeImageStyles }}
          />

          <img
            className="tall-bg"
            src={mobileImage}
            alt={title}
            style={{ ...activeImageStyles }}
          />

          <div className="overlay" />
        </div>
        <div className={`tiles ${showContent ? 'active' : ''}`}>
          {tiles?.length > 0
            ? tiles.map((tile, i) => (
                <div
                  key={i}
                  style={{
                    borderColor: tile.outline_color,
                  }}
                >
                  <div>
                    <button
                      onClick={e => handleTileClick(e, tile)}
                      key={tile.heading + i}
                    >
                      <img
                        src={tile.thumbnail}
                        alt={tile.heading}
                        style={{
                          objectPosition:
                            tile.backgroundPosition?.backgroundPosition ||
                            'center',
                        }}
                      />

                      <div
                        className="color-overlay"
                        style={{
                          backgroundColor: tile.backgroundColor,
                        }}
                      />
                    </button>

                    <div className="tile-button-copy">
                      <h3>
                        <span
                          dangerouslySetInnerHTML={{ __html: tile.heading }}
                        />
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>

        <div className={`active-tile ${activeTile ? 'active' : ''}`}>
          {activeTile && (
            <>
              {activeTile?.video ? (
                <div className="active-tile-image">
                  <video
                    src={activeTile?.video}
                    playsInline
                    autoPlay
                    muted
                    loop
                  />
                </div>
              ) : (
                <div className="active-tile-image">
                  <img src={activeTile?.image} alt={activeTile.heading} />
                </div>
              )}

              <div className="active-tile-content-container">
                <div
                  className={`active-tile-content ${readMore ? 'open' : ''}`}
                >
                  <div className="active-tile-title">
                    <h1
                      dangerouslySetInnerHTML={{
                        __html: activeTile.heading || '',
                      }}
                    />
                    {activeTile.content && (
                      <button
                        className={`slide-content-toggle ${
                          readMore ? 'open' : ''
                        }`}
                        onClick={() => setReadMore(!readMore)}
                      >
                        {readMore ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                  </div>
                  {activeTile.content && (
                    <animated.div
                      style={{
                        overflow: readMore ? 'visible' : 'hidden',
                        ...style,
                      }}
                    >
                      <div
                        ref={contentRef}
                        className="tile-content"
                        dangerouslySetInnerHTML={{
                          __html: activeTile.content,
                        }}
                      />
                    </animated.div>
                  )}
                  <button
                    className="back-button"
                    onClick={() => changeTile(null)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}

ShipAreas.propTypes = {
  navTitle: PropTypes.string,
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  content: PropTypes.shape({}),
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  height: PropTypes.any,
}

export default ShipAreas
