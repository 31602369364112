import {
  getAgentData,
  getBrandData,
  getContent,
  getStateOptions,
} from '../api/api'

const bootstrapApp = () => {
  const result = {}
  const searchParams = new URLSearchParams(window.location.search)

  const ssoParam =
    searchParams.get('sso') ||
    searchParams.get('z') ||
    searchParams.get('cptok')

  const promiseList = [
    getContent()
      .then(res => {
        result.content = res
      })
      .catch(e => {
        result.contentError =
          e?.response?.['hydra:member']?.description ||
          e?.message ||
          'Unable to load content'
      }),
    getBrandData().then(res => {
      result.brandData = res
    }),
    getStateOptions().then(res => {
      if (res) {
        result.stateOptions = res
      }
    }),
  ]
  if (ssoParam) {
    promiseList.push(
      getAgentData(ssoParam)
        .then(res => {
          result.agentData = res
        })
        .catch(err => {
          result.agentError =
            err?.response?.data?.['hydra:description'] ||
            err?.message ||
            'Unable to authenticate agent'
        }),
    )
  }

  return promiseList.length
    ? Promise.allSettled(promiseList).then(() => {
        return result
      })
    : Promise.resolve({})
}

export default bootstrapApp
