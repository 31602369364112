import { useNavigate } from 'react-router-dom'

const useBindRelativeLinks = contentRef => {
  const navigate = useNavigate()
  const searchParams = new URLSearchParams(window.location.search)

  const ssoParam =
    searchParams.get('sso') ||
    searchParams.get('z') ||
    searchParams.get('cptok')

  setTimeout(() => {
    if (contentRef.current) {
      const linkElements = [...contentRef.current.getElementsByTagName('a')]
      linkElements.forEach(linkElement => {
        // Check if relative path
        const href = linkElement.getAttribute('href')
        if (href.substring(0, 1) === '/' && !linkElement.dataset.bound) {
          linkElement.addEventListener('click', e => {
            e.preventDefault()
            window.scroll({ top: 0, behavior: 'instant' })
            navigate(`${href}?sso=${ssoParam}`)
          })
          linkElement.setAttribute('href', `${href}?sso=${ssoParam}`)
          linkElement.dataset.bound = true
        }
      })
    }
  }, 0)
}

export default useBindRelativeLinks
