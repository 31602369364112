import PropTypes from 'prop-types'
import defaultNext from '../../assets/icons/default_slider_chev_next.png'
import defaultPrev from '../../assets/icons/default_slider_chev_prev.png'

const navDefaultProps = {
  style: {},
  icon: null,
}

const navPropTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  icon: PropTypes.string,
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
}

export const NextArrow = ({
  className,
  style,
  onClick,
  icon,
  currentSlide,
  slideCount,
}) => {
  const src = icon || defaultNext
  const showIcon = Math.ceil(currentSlide + 1) < slideCount
  return (
    <div
      className={`${className} ${showIcon ? 'active' : 'hidden'}`}
      style={{ ...style }}
      onClick={showIcon ? onClick : null}
      role="button"
    >
      <img src={src} alt="next" />
    </div>
  )
}
NextArrow.propTypes = navPropTypes
NextArrow.defaultProps = navDefaultProps
export const PrevArrow = ({
  className,
  style,
  onClick,
  icon,
  currentSlide,
}) => {
  const src = icon || defaultPrev
  const showIcon = currentSlide > 0
  return (
    <div
      className={`${className} ${showIcon ? 'active' : 'hidden'}`}
      style={{ ...style }}
      onClick={showIcon ? onClick : null}
      role="button"
    >
      <img src={src} alt="previous" />
    </div>
  )
}

PrevArrow.propTypes = navPropTypes
PrevArrow.defaultProps = navDefaultProps
