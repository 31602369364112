import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import './modal.scss'
import { useEffect } from 'react'

ReactModal.setAppElement('#icon')

const Modal = ({ isOpen = false, onRequestClose = null, children }) => {
  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
    return () => (document.body.style.overflow = 'unset')
  }, [isOpen])
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose ? onRequestClose : () => null}
      className="modal"
      overlayClassName="modal-overlay"
    >
      {children}
    </ReactModal>
  )
}

Modal.defaultProps = {
  onRequestClose: null,
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Modal
