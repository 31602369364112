import PropTypes from 'prop-types'
import Background from '../background/Background'
import GradientText from '../gradientText/GradientText'
import './panel-block.scss'
import { useRef } from 'react'
import useBindRelativeLinks from '../../hooks/useBindRelativeLink'
import { snakeCase } from 'lodash/string'

const PanelBlock = ({
  title,
  subTitle,
  titleColor,
  subTitleColor,
  height,
  content,
  background,
  borderTopColor,
  panelSide,
  panelMaxWidth,
  panelBackground,
  edgeMargin,
  linkData,
  navTitle,
}) => {
  const isMobile = window.innerWidth < 800

  const contentRef = useRef(null)
  useBindRelativeLinks(contentRef)

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="PanelBlock">
      <Background {...background} height={height}>
        {borderTopColor && (
          <div
            className="border-top"
            style={{ background: borderTopColor }}
          ></div>
        )}
        <div
          className={`panel-block ${
            panelSide === 'right' ? 'panel-right' : ''
          }`}
        >
          <div
            className="panel"
            style={{
              background: panelBackground,
              maxWidth: panelMaxWidth ? `${panelMaxWidth}px` : '',
              margin: !isMobile && edgeMargin ? `0 ${edgeMargin / 4}%` : '',
            }}
          >
            {title && (
              <GradientText type="h1" color={titleColor}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </GradientText>
            )}
            {subTitle && (
              <GradientText type="h2" color={subTitleColor}>
                <span dangerouslySetInnerHTML={{ __html: subTitle }} />
              </GradientText>
            )}
            {content && (
              <div
                className="content"
                ref={contentRef}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
          </div>
        </div>
      </Background>
    </section>
  )
}

PanelBlock.propTypes = {
  background: PropTypes.shape({}),
  borderTopColor: PropTypes.string,
  content: PropTypes.string,
  edgeMargin: PropTypes.number,
  height: PropTypes.string,
  linkData: PropTypes.shape({
    target: PropTypes.string,
    text: PropTypes.string,
  }),
  panelBackground: PropTypes.string,
  panelMaxWidth: PropTypes.string,
  panelSide: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  navTitle: PropTypes.string,
}

export default PanelBlock
