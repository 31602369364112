import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import dateFormats from '../../config/dateFormats'

import './sailings.scss'
import stateRoomLabels from '../../config/stateRoomLabels'
import cancelationChargeAmounts from '../../config/cancelationChargeAmounts'
import { getCurrencySymbol } from '../../utils/getCurrencySymbol'

const stateRoomOrder = ['I', 'O', 'B', 'D']

const Sailings = ({
  sailings,
  handleRequestClick,
  handleOpenItineraryModal,
  currencyCode,
}) => {
  const sameDestination = sailings.every(
    i => i.destinationCode === sailings[0].destinationCode,
  )

  const currencySymbol = getCurrencySymbol(currencyCode)

  return (
    <div
      className={`sailings ${
        sameDestination
          ? 'sailings-same-destination'
          : 'sailings-different-destinations'
      }`}
    >
      <div className="sailing-items">
        {sailings.map(sailing => {
          const {
            key,
            description,
            sailDate,
            shipName,
            pricing,
            portName,
            image,
            id,
            taxesAndFees,
            sailingNights,
            itineraryImage,
          } = sailing
          return (
            <div key={key} className="sailing-item">
              {!sameDestination && image ? (
                <img src={image} alt={description} />
              ) : null}
              <div className="sailing-item-title-box">
                <button
                  className="remove-margin sailing-item-name bold"
                  onClick={() =>
                    handleOpenItineraryModal({
                      key,
                      id,
                      sailDate,
                      img: itineraryImage,
                    })
                  }
                >
                  {sailingNights} Nights {description}
                </button>
                {sailDate && (
                  <p className="remove-margin">
                    <span className="sailing-item-label">Departure:</span>{' '}
                    <span className="sailing-item-value bold">
                      {dayjs(sailDate).format(dateFormats.shortDisplay)}
                    </span>
                  </p>
                )}
                {portName && (
                  <p className="remove-margin">
                    <span className="sailing-item-label">Departure Port:</span>{' '}
                    <span className="sailing-item-value bold">{portName}</span>
                  </p>
                )}
                <p className="remove-margin">
                  <span className="sailing-item-label">Ship:</span>{' '}
                  <span className="sailing-item-value bold">
                    {shipName === 'IC' ? 'Icon of the Seas' : shipName}
                  </span>
                </p>

                <p className="remove-margin taxes-and-fees">
                  <em>
                    Taxes, fees & port expenses: {currencySymbol}
                    {taxesAndFees} {currencyCode}
                  </em>
                </p>
              </div>
              <div className="sailing-item-descriptions">
                {pricing?.length
                  ? pricing
                      .sort((a, b) =>
                        stateRoomOrder.indexOf(a.code) <
                        stateRoomOrder.indexOf(b.code)
                          ? -1
                          : 1,
                      )
                      .map(stateRoom => {
                        const {
                          code,
                          description,
                          price,
                          leadPromoDesc,
                          promos,
                          perPersonCreditAmount,
                          isRefundable,
                        } = stateRoom
                        return (
                          <div
                            key={code}
                            className={
                              sameDestination
                                ? 'sailing-item-description-box'
                                : 'description'
                            }
                          >
                            {stateRoomLabels[description] && (
                              <p className="remove-margin descriptions-font">
                                {stateRoomLabels[description]}:
                              </p>
                            )}
                            {price ? (
                              <>
                                <p className="remove-margin bold sailing-price">
                                  From {currencySymbol}
                                  {price} {currencyCode}
                                </p>
                                {Number(perPersonCreditAmount) > 0 && (
                                  <p className="remove-margin descriptions-font-13">
                                    {perPersonCreditAmount} OBC
                                  </p>
                                )}
                                {leadPromoDesc && (
                                  <p className="remove-margin descriptions-font-13">
                                    {leadPromoDesc}
                                    {promos?.length > 1 ? '+' : ''}
                                  </p>
                                )}
                                <span className="remove-margin descriptions-font-13">
                                  {isRefundable ? '' : 'Non-'}Refundable Deposit
                                </span>
                              </>
                            ) : (
                              <p className="remove-margin sailing-price">
                                Sold Out
                              </p>
                            )}
                          </div>
                        )
                      })
                  : null}
              </div>
              <div className="sailing-item-button-box">
                <button
                  onClick={() => handleRequestClick(sailing)}
                  className={
                    sameDestination
                      ? 'btn-with-radius btn-yellow'
                      : 'btn-yellow'
                  }
                >
                  Make a request
                </button>
              </div>
            </div>
          )
        })}
      </div>
      <div className="sailing-disclaimer">
        <p>
          <em>
            Price is per person, in {currencyCode}, for cruise only on select
            sailings and stateroom categories, based on double-occupancy,
            reflects any promotional savings, and is subject to change and
            availability. Taxes, fees, and port expenses are additional.
            Bookings made under the Non-Refundable Deposit Program are subject
            to a {currencySymbol}
            {cancelationChargeAmounts[currencyCode]} {currencyCode} cancellation
            or change fee per guest. Price includes non-commissionable cruise
            fare. Additional restrictions apply, including the terms and
            conditions of any promotions and pricing programs. © 2023 Royal
            Caribbean. Ship’s registry: Bahamas
          </em>
        </p>
      </div>
    </div>
  )
}

Sailings.propTypes = {
  currencyCode: PropTypes.string,
  handleRequestClick: PropTypes.func.isRequired,
  sailings: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      destinationCode: PropTypes.string,
      largeImage: PropTypes.string,
    }),
  ),
  handleOpenItineraryModal: PropTypes.func.isRequired,
}

export default Sailings
