import { useState, useEffect } from 'react'
import './app.scss'
import bootstrapApp from '../../utils/bootstrapApp'
import LoadingSpinner from '../../components/loadingSpinner/LoadingSpinner'
import encodeKeys from '../../config/encodeKeys'

import Routes from '../routes/Routes'
import triggerAnalyticsEvent from '../../utils/triggerAnalyticsEvent'

const decodePurl = code => {
  try {
    const rawData = JSON.parse(atob(code))
    return Object.entries(rawData).reduce((obj, [key, value]) => {
      const appKey = Object.entries(encodeKeys)?.find(
        ([, value]) => value === key,
      )?.[0]

      if (appKey) {
        return {
          ...obj,
          [appKey]: value,
        }
      }
      return obj
    }, {})
  } catch (e) {}
}

function App() {
  const [appIsLoading, setAppIsLoading] = useState(true)
  const [appData, setAppData] = useState({})
  const [agentOverrides, setAgentOverrides] = useState(null)
  const { agentData, brandData, content, contentError } = appData
  const [itineraries, setItineraries] = useState([])
  const isAuthenticated = !!agentData
  // without either a successful authentication or a purl we will hide all booking links and sections.
  const canViewBookings = !!agentOverrides

  const searchParams = new URLSearchParams(window.location.search)

  const purl = searchParams.get('id')

  let purlData
  if (purl) {
    try {
      purlData = decodePurl(purl)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    // StrictMode renders components twice (on dev but not production) in order to detect any problems with your code and warn you about them (which can be quite useful)
    bootstrapApp()
      .then(res => {
        if (res?.agentData || purlData) {
          setAgentOverrides(
            purlData || {
              ...res?.agentData,
              customMessage:
                res?.content?.booking?.defaultMessage ||
                'I am pleased to reveal the newest ship in the Royal Caribbean Fleet, and hope this is the start of creating iconic vacations together.',
            },
          )
          setAppData(res)

          if (res?.agentData) {
            triggerAnalyticsEvent('iconTravelMicrositeLaunched', {
              agencyId: res?.agentData?.agencyId,
              agentId: res?.agentData?.agentId,
            })
          } else if (purlData) {
            triggerAnalyticsEvent('iconConsumerMicrositeLaunched', {
              agencyId: purlData?.agencyId,
              agentId: purlData?.agentId,
            })
          }
        } else {
          window.location.href = process.env.REACT_APP_CRUISING_POWER_LOGIN
        }
      })
      .finally(() => setAppIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (contentError) {
    return <p>{contentError}</p>
  }

  if (appIsLoading) {
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <Routes
      content={content}
      brandData={brandData}
      canViewBookings={canViewBookings}
      isAuthenticated={isAuthenticated}
      appData={appData}
      agentOverrides={agentOverrides}
      setAgentOverrides={setAgentOverrides}
      purlData={purlData}
      itineraries={itineraries}
      setItineraries={setItineraries}
    />
  )
}

export default App
