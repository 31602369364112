import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSpring, animated } from '@react-spring/web'
import useBindRelativeLinks from '../../hooks/useBindRelativeLink'

const Slide = ({ slide }) => {
  const [readMore, setReadMore] = useState(false)
  const contentRef = useRef(null)
  useBindRelativeLinks(contentRef)

  const [style, setStyle] = useSpring(() => ({ height: '0px' }), [])

  useEffect(() => {
    if (contentRef?.current) {
      setStyle({
        height: `${readMore ? contentRef.current.offsetHeight : 0}px`,
      })
    }
  }, [readMore, contentRef, setStyle])

  return (
    <div className={`slide ${readMore ? 'open' : ''}`}>
      {slide.placeholderImage ? (
        <img
          className="placeholder-image"
          alt={slide.title}
          src={slide.placeholderImage}
        />
      ) : (
        ''
      )}
      {slide.type === 'video' ? (
        <video src={slide.src} playsInline autoPlay muted loop />
      ) : (
        <img src={slide.src} alt="" />
      )}
      {slide?.content && (
        <div className={`slide-content-container`}>
          <div>
            {slide?.subtitle && (
              <div
                className="slide-subheader"
                dangerouslySetInnerHTML={{ __html: slide.subtitle }}
              ></div>
            )}
            <div className="slide-header">
              {slide.title && (
                <h2
                  className="slide-title"
                  dangerouslySetInnerHTML={{
                    __html: slide.title,
                  }}
                />
              )}
              {slide.content && (
                <button
                  className={`slide-content-toggle ${readMore ? 'open' : ''}`}
                  onClick={() => setReadMore(!readMore)}
                >
                  {readMore ? 'Read Less' : 'Read More'}
                </button>
              )}
            </div>
            {slide.content && (
              <animated.div
                style={{ overflow: readMore ? 'visible' : 'hidden', ...style }}
              >
                <div
                  ref={contentRef}
                  className="slide-content"
                  dangerouslySetInnerHTML={{
                    __html: slide.content,
                  }}
                />
              </animated.div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

Slide.propTypes = {
  slide: PropTypes.shape({
    content: PropTypes.string,
    src: PropTypes.string,
    subtitle: PropTypes.any,
    title: PropTypes.string,
    type: PropTypes.string,
    placeholderImage: PropTypes.string,
  }),
}

export default Slide
