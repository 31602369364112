import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import Background from '../background/Background'
import './video-block.scss'
import muteIcon from '../../assets/icons/mute.svg'
import unmuteIcon from '../../assets/icons/unmute.svg'
import playIcon from '../../assets/icons/play.svg'
import pauseIcon from '../../assets/icons/pause.svg'
import { snakeCase } from 'lodash'

const VideoBlock = ({ height, background, navTitle }) => {
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMute, setIsMute] = useState(true)
  const videoRef = useRef(null)

  function togglePlaying() {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  function toggleMute() {
    videoRef.current.muted = !isMute
    setIsMute(!isMute)
  }
  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="VideoBlock">
      <Background {...background} height={height} ref={videoRef}>
        <div className="video-block">
          <div className="media-controls">
            {isPlaying ? (
              <button onClick={togglePlaying} aria-label="Pause Video">
                <img src={pauseIcon} alt="Pause Video" />
              </button>
            ) : (
              <button onClick={togglePlaying} aria-label="Play Video">
                <img src={playIcon} alt="Play Video" />
              </button>
            )}
            {isMute ? (
              <button onClick={toggleMute} aria-label="Unmute Video">
                <img src={muteIcon} alt="Unmute Video" />
              </button>
            ) : (
              <button onClick={toggleMute} aria-label="Mute Video">
                <img src={unmuteIcon} alt="Mute Video" />
              </button>
            )}
          </div>
        </div>
      </Background>
    </section>
  )
}

VideoBlock.propTypes = {
  background: PropTypes.shape({}),
  height: PropTypes.number,
  navTitle: PropTypes.string,
}

export default VideoBlock
