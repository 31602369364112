import ContentBlock from '../components/contentBlock/ContentBlock'
import DoubleContentBlock from '../components/doubleContentBlock/DoubleContentBlock'
import Hero from '../components/hero/Hero'
import PanelBlock from '../components/panelBlock/PanelBlock'
import SliderBlock from '../components/sliderBlock/SliderBlock'
import ShipAreas from '../components/shipAreas/ShipAreas'
import RoomsBlock from '../components/roomsBlock/RoomsBlock'
import TabsBlock from '../components/tabsBlock/TabsBlock'
import VideoBlock from '../components/videoBlock/VideoBlock'
import PanelSliderBlock from '../components/panelSliderBlock/PanelSliderBlock'

const blocks = {
  Hero,
  ContentBlock,
  DoubleContentBlock,
  PanelBlock,
  SliderBlock,
  ShipAreas,
  TabsBlock,
  RoomsBlock,
  VideoBlock,
  PanelSliderBlock,
}

export default blocks
