import { ErrorMessage, Field, Formik } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import LoadingSpinner from '../loadingSpinner/LoadingSpinner'
import { postEmail } from '../../api/api'
import { useState } from 'react'
import Modal from '../modal/Modal'
import WhyWeAsk from './WhyWeAsk'
import stateRoomLabels from '../../config/stateRoomLabels'
const BookingForm = ({
  stateOptions,
  stateRoomOptions,
  sailing,
  agentData,
  dismissModal,
}) => {
  const [whyWeAskModalIsOpen, setWhyWeAskModalIsOpen] = useState(false)
  const [submitState, setSubmitState] = useState(null)

  const submit = async values => {
    setSubmitState('loading')
    try {
      await postEmail({
        ...values,
        sailDate: sailing?.sailDate || '',
        agentEmail: agentData?.agentEmail || '',
        shipCode: sailing?.shipCode || '',
        brandCode: sailing?.brand || '',
      })
      return setSubmitState('success')
    } catch (e) {
      return setSubmitState('error')
    }
  }
  /*
  missing from example:
    "fareCode": "f",
    "flyingFrom": "",

  extra data on our end:
    brandCode: 'R',
  */

  const closeModal = () => {
    setSubmitState(false)
    dismissModal()
  }

  return (
    <div className="form-sections">
      <div className="title-info">
        <div className="form-section-header">
          {submitState !== 'loading' && (
            <>
              <div className="close-button">
                <button onClick={closeModal}>X Close Form</button>
              </div>
              <h1>
                {submitState === 'success'
                  ? 'Thank You'
                  : 'COMPLETE THE FORM BELOW AND AN AGENT WILL BE IN TOUCH.'}
              </h1>
            </>
          )}
        </div>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            stateProv: '',
            contactMethod: 'email',
            email: '',
            phone: '',
            datesAreFlexible: '',
            airFare: '',
            airCity: '',
            numGuests: '',
            stateRoomPref: '',
            senior: false,
            lawEnforcement: false,
            fireOrEMT: false,
            militaryMember: false,
            loyaltyNumber: '',
            additionalComments: '',
          }}
          onSubmit={submit}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Please enter your first name'),
            lastName: Yup.string().required('Please enter your last name'),
            stateProv: Yup.string().required(
              'Please select a state or province',
            ),
            contactMethod: Yup.string().required(
              'Please select your preferred method of contact',
            ),
            email: Yup.string().when('contactMethod', {
              is: 'email',
              then: schema =>
                schema
                  .email('Proper formatting required. eg. name@domain.com')
                  .required('Please enter a valid email address'),
            }),
            phone: Yup.string().when('contactMethod', {
              is: 'phone',
              then: schema => schema.required('Please enter your Phone Number'),
            }),
            datesAreFlexible: Yup.string().required(
              'Please specify if your dates are flexible',
            ),
            airFare: Yup.string().required(
              'Please specify if you need Air Arrangements',
            ),
            airCity: Yup.string().when('airFare', {
              is: 'yes',
              then: schema =>
                schema.required(
                  'Please enter the city you will be flying from',
                ),
            }),
            numGuests: Yup.string().required('Please select Number of Guests'),
            stateRoomPref: Yup.string().required(
              'Please select the Stateroom Type',
            ),
          })}
        >
          {({ values, touched, errors, handleSubmit, isSubmitting }) => {
            const hasError = name => !!(touched[name] && errors[name])
            if (submitState === 'success') {
              return (
                <div className="success-message">
                  <p>Your email has been sent.</p>
                  <button className="btn-yellow" onClick={closeModal}>
                    Return
                  </button>
                </div>
              )
            }

            return isSubmitting ? (
              <LoadingSpinner />
            ) : (
              <form onSubmit={handleSubmit}>
                <hr className="line-break"></hr>
                {submitState === 'error' && (
                  <div className="error-message">
                    <p>There was an error processing your request</p>
                    <p>Please review the form and try again.</p>
                  </div>
                )}
                <div className="name-section">
                  {/* <div className="flex"> */}
                  <div className="input-group">
                    <label>
                      <p>
                        First Name <span className="required-sign">*</span>
                      </p>
                      <Field
                        name="firstName"
                        className={hasError('firstName') ? 'error' : ''}
                      />
                    </label>
                    <div className="error-section">
                      <ErrorMessage name="firstName" />
                    </div>
                  </div>
                  <div className="input-group">
                    <label>
                      <p>
                        Last Name <span className="required-sign">*</span>
                      </p>
                      <Field
                        name="lastName"
                        className={hasError('lastName') ? 'error' : ''}
                      />
                    </label>
                    <div className="error-section">
                      <ErrorMessage name="lastName" />
                    </div>
                  </div>
                  <div className="input-group">
                    <label>
                      <p>
                        State/Province <span className="required-sign">*</span>
                      </p>
                      <Field
                        as="select"
                        name="stateProv"
                        className={hasError('stateProv') ? 'error' : ''}
                      >
                        <option value=""></option>
                        {stateOptions?.map(({ code, name }) => (
                          <option key={code} value={code}>
                            {name}
                          </option>
                        ))}
                      </Field>
                    </label>
                    <div className="error-section">
                      <ErrorMessage name="stateProv" />
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <hr className="line-break"></hr>
                <div className="mail-section">
                  <div className="input-group">
                    <div
                      className="group"
                      role="group"
                      aria-labelledby="contact-radio-group"
                    >
                      <p>How May We Contact You?</p>
                      <label className="flex">
                        <Field
                          type="radio"
                          name="contactMethod"
                          value="email"
                        />
                        <p>Email</p>
                      </label>
                      <label className="flex">
                        <Field
                          type="radio"
                          name="contactMethod"
                          value="phone"
                        />
                        <p>Phone</p>
                      </label>
                    </div>
                    <div>
                      <ErrorMessage name="contactMethod" />
                    </div>
                  </div>

                  {values.contactMethod === 'email' ? (
                    <div className="input-group">
                      <label>
                        <p>
                          Email Address <span className="required-sign">*</span>
                        </p>
                        <Field
                          name="email"
                          className={hasError('email') ? 'error' : ''}
                        />
                      </label>
                      <div className="error-message error-section">
                        <ErrorMessage name="email" />
                      </div>
                    </div>
                  ) : (
                    <div className="input-group">
                      <label>
                        <p>
                          Phone Number <span className="required-sign">*</span>
                        </p>
                        <Field
                          name="phone"
                          className={hasError('phone') ? 'error' : ''}
                        />
                      </label>
                      <div className="error-section">
                        <ErrorMessage name="phone" />
                      </div>
                    </div>
                  )}
                </div>
                <hr className="line-break"></hr>
                <div className="option-section">
                  <div className="input-group">
                    <div
                      role="group"
                      aria-labelledby="dates-are-flexible-radio-group"
                      className="flex options"
                    >
                      <p>
                        Are Your Dates Flexible?{' '}
                        <span className="required-sign">*</span>
                      </p>
                      <div className="option">
                        <label className="flex">
                          <Field
                            type="radio"
                            name="datesAreFlexible"
                            value="yes"
                          />
                          <p>Yes</p>
                        </label>
                        <label className="flex">
                          <Field
                            type="radio"
                            name="datesAreFlexible"
                            value="no"
                          />
                          <p>No</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="error-message">
                    <ErrorMessage name="datesAreFlexible" />
                  </div>
                  <div className="input-group">
                    <div
                      role="group"
                      aria-labelledby="dates-are-flexible-radio-group"
                      className="flex options"
                    >
                      <p>
                        Do You Need Air Arrangements?{' '}
                        <span className="required-sign">*</span>
                      </p>
                      <div className="option">
                        <label className="flex">
                          <Field type="radio" name="airFare" value="yes" />
                          <p>Yes</p>
                        </label>
                        <label className="flex">
                          <Field type="radio" name="airFare" value="no" />
                          <p>No</p>
                        </label>
                      </div>
                    </div>
                  </div>
                  {values.airFare === 'yes' && (
                    <div className="input-group">
                      <label>
                        <p>
                          What city will you be flying from?{' '}
                          <span className="required-sign">*</span>
                        </p>
                        <Field
                          name="airCity"
                          className={hasError('airCity') ? 'error' : ''}
                        />
                      </label>
                      <div className="error-message error-section">
                        <ErrorMessage name="airCity" />
                      </div>
                    </div>
                  )}
                  <div className="error-message">
                    <ErrorMessage name="airFare" />
                  </div>
                </div>
                <hr className="line-break"></hr>
                <div className="detail-section">
                  <label>
                    <p>
                      Number of Guests <span className="required-sign">*</span>
                    </p>
                    <Field
                      as="select"
                      name="numGuests"
                      className={hasError('numGuests') ? 'error' : ''}
                    >
                      <option value=""></option>
                      {Array.from({ length: 4 }, (_, i) => ({
                        label: i + 1,
                        value: i + 1,
                      }))?.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                      <option key="5 or more" value="5 or more">
                        5 or more
                      </option>
                    </Field>
                    <ErrorMessage name="numGuests" />
                  </label>
                  <label>
                    <p>
                      Preferred Stateroom Type{' '}
                      <span className="required-sign">*</span>
                    </p>
                    <Field
                      as="select"
                      name="stateRoomPref"
                      className={hasError('stateRoomPref') ? 'error' : ''}
                    >
                      <option value=""></option>
                      {stateRoomOptions?.map(({ label, value }) => (
                        <option
                          key={value}
                          value={stateRoomLabels?.[label] || label}
                        >
                          {stateRoomLabels?.[label] || label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="stateRoomPref" />
                  </label>
                </div>

                <div className="criteria-section">
                  <p>
                    Do Any Guests Meet The Following Criteria?{' '}
                    <button
                      className="why-we-ask-button"
                      onClick={() => setWhyWeAskModalIsOpen(true)}
                      type="button"
                    >
                      Why We Ask?
                    </button>
                  </p>
                  <div className="criteria-options">
                    <label className="flex">
                      <Field type="checkbox" name="senior" />
                      <p>Age 55+</p>
                    </label>
                    <label className="flex">
                      <Field type="checkbox" name="lawEnforcement" />
                      <p>U.S. or Canadian Law Enforcement</p>
                    </label>
                    <label className="flex">
                      <Field type="checkbox" name="fireOrEMT" />
                      <p>Fire Department or EMT</p>
                    </label>
                    <label className="flex">
                      <Field type="checkbox" name="militaryMember" />
                      <p>U.S. Military or Canadian Forces</p>
                    </label>
                  </div>
                </div>
                <hr className="line-break"></hr>
                <div className="id-number-section">
                  <div className="input-group">
                    <label>
                      <p>Crown & Anchor Loyalty ID</p>
                      <Field name="loyaltyNumber" />
                    </label>
                  </div>
                </div>
                <hr className="line-break"></hr>
                <div className="comment-section">
                  <div className="input-group">
                    <label>
                      <p>Additional Comments</p>
                      <Field name="additionalComments" as="textarea" />
                    </label>
                  </div>
                </div>
                <hr className="line-break"></hr>
                <div className="submit-button">
                  <button type="submit">Submit Your Request</button>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
      <Modal
        isOpen={whyWeAskModalIsOpen}
        onRequestClose={() => setWhyWeAskModalIsOpen(false)}
      >
        <WhyWeAsk onCloseClick={() => setWhyWeAskModalIsOpen(false)} />
      </Modal>
    </div>
  )
}

BookingForm.propTypes = {
  stateOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  stateRoomOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sailing: PropTypes.shape({
    sailDate: PropTypes.string,
    shipCode: PropTypes.string,
    brand: PropTypes.string,
  }),
  agentData: PropTypes.shape({
    agentEmail: PropTypes.string,
  }),
  dismissModal: PropTypes.func.isRequired,
}

export default BookingForm
