import { Field, Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'

import encodeKeys from '../../config/encodeKeys'

import './agent-purl-form.scss'

const AgentDetailsForm = ({ initialValues, onCreatePurlSuccess }) => {
  const [hasCreatedPurl, setHasCreatedPurl] = useState(false)
  const handleSubmit = async values => {
    const data = Object.entries(values).reduce((obj, [key, value]) => {
      const encodeKey = encodeKeys[key] || key
      return {
        ...obj,
        [encodeKey]: value,
      }
    }, {})
    let code
    try {
      code = btoa(JSON.stringify(data))
    } catch (e) {
      console.log(e)
      return null
    }
    if (code) {
      onCreatePurlSuccess(values)
      const value = `${process.env.REACT_APP_HOST_URL}?id=${code}`
      try {
        await navigator.clipboard.writeText(value)
        return setHasCreatedPurl(true)
      } catch (e) {}
      prompt(
        'Unable to copy to clipboard please copy here',
        `${process.env.REACT_APP_HOST_URL}?id=${code}`,
      )
    } else {
      throw new Error('Unable to create personal URL')
    }
    return setHasCreatedPurl(true)
  }

  return (
    <Formik
      initialValues={initialValues || {}}
      onSubmit={handleSubmit}
      validationSchema={yup.object({
        agentName: yup.string().required('Please enter a name'),
        agentEmail: yup
          .string()
          .required('Please enter an email')
          .email('Please enter a valid email'),
        phone: yup.string().required('Please enter a phone number'),
        agency: yup.string().required('Please enter an agency name'),
        customMessage: yup
          .string()
          .max(200, 'Message cannot exceed 200 characters'),
      })}
    >
      {({ handleSubmit, errors, touched }) => {
        const getError = name =>
          errors[name] && touched[name] ? errors[name] : null
        return (
          <form
            onSubmit={handleSubmit}
            className="agent-purl-form"
            onChange={() => setHasCreatedPurl(false)}
          >
            <div className="agent-input-group left">
              <label className={getError('agentName') ? 'error' : ''}>
                <p>Agent Name:</p>
                <Field name="agentName" />
              </label>
              {getError('agentName') && (
                <p className="error-message">{getError('agentName')}</p>
              )}
            </div>
            <div className="agent-input-group right">
              <label className={getError('agentEmail') ? 'error' : ''}>
                <p>Email:</p>
                <Field name="agentEmail" />
              </label>
              {getError('agentEmail') && (
                <p className="error-message">{getError('agentEmail')}</p>
              )}
            </div>

            <div className="agent-input-group left">
              <label className={getError('agency') ? 'error' : ''}>
                <p>Agency:</p>
                <Field name="agency" />
              </label>
              {getError('agency') && (
                <p className="error-message">{getError('agency')}</p>
              )}
            </div>
            <div className="agent-input-group right">
              <label className={getError('phone') ? 'error' : ''}>
                <p>Phone:</p>
                <Field name="phone" />
              </label>

              {getError('phone') && (
                <p className="error-message">{getError('phone')}</p>
              )}
            </div>

            <div className="agent-input-group message-group">
              <label className={getError('customMessage') ? 'error' : ''}>
                <p>Custom Message:</p>
                <Field
                  name="customMessage"
                  as="textarea"
                  placeholder="I am pleased to reveal the newest ship in the Royal Caribbean Fleet, and hope this is the start of creating iconic vacations together."
                />
              </label>
              {getError('customMessage') && (
                <p className="error-message">{getError('customMessage')}</p>
              )}
            </div>

            <div className="agent-submit-container">
              <button className="btn-yellow btn-with-radius" type="submit">
                {hasCreatedPurl
                  ? 'Custom Link Copied'
                  : 'Share with your clients'}
              </button>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}

AgentDetailsForm.propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  onCreatePurlSuccess: PropTypes.func.isRequired,
}

export default AgentDetailsForm
