import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { NextArrow, PrevArrow } from './SliderNavs'
import Background from '../background/Background'
import './slider-block.scss'
import Slide from './Slide'
import { snakeCase } from 'lodash'

const SliderBlock = ({ background, borderTopColor, slides, navTitle }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="SliderBlock">
      <Background {...background}>
        {borderTopColor && (
          <div className="border-top" style={{ background: borderTopColor }} />
        )}
        <div className="slider-block">
          {slides?.length > 0 ? (
            <div className="slider">
              <Slider {...settings}>
                {slides.map(slide => (
                  <Slide slide={slide} key={slide.key} />
                ))}
              </Slider>
            </div>
          ) : (
            ''
          )}
        </div>
      </Background>
    </section>
  )
}

SliderBlock.propTypes = {
  background: PropTypes.shape({}),
  borderTopColor: PropTypes.string,
  content: PropTypes.string,
  height: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  navTitle: PropTypes.string,
}

export default SliderBlock
