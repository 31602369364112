import React from 'react'
import PropTypes from 'prop-types'
import AgentDetailsForm from './AgentDetailsForm'

const AgentDetails = ({ agentData, isAuthenticated, onCreatePurlSuccess }) => {
  const {
    agencyId,
    agentId,
    agentName,
    agentEmail,
    agency,
    phone,
    customMessage,
    currencyCode,
  } = agentData
  return isAuthenticated ? (
    <div className="booking-agent-details">
      <AgentDetailsForm
        onCreatePurlSuccess={onCreatePurlSuccess}
        initialValues={{
          agencyId,
          agentId,
          agentName,
          agentEmail,
          agency,
          phone,
          customMessage,
          currencyCode,
        }}
      />
    </div>
  ) : (
    <div className="booking-agent-details">
      <div className="agent-details-item">
        <p className="remove-margin agent-details-label bold">Agent Name:</p>
        {agentName && (
          <p className="remove-margin agent-details-text">{agentName}</p>
        )}
      </div>
      <div className="agent-details-item">
        <p className="remove-margin agent-details-label bold">Email:</p>
        {agentEmail && (
          <p className="remove-margin agent-details-text">{agentEmail}</p>
        )}
      </div>
      <div className="agent-details-item">
        <p className="remove-margin agent-details-label bold">Agency:</p>
        {agency && (
          <p className="remove-margin agent-details-text agency-name ">
            {agency}
          </p>
        )}
      </div>
      <div className="agent-details-item">
        <p className="remove-margin agent-details-label bold">Phone:</p>
        {phone && <p className="remove-margin agent-details-text">{phone}</p>}
      </div>
    </div>
  )
}

AgentDetails.defaultProps = {
  isAuthenticated: false,
}

AgentDetails.propTypes = {
  agentData: PropTypes.shape({
    agency: PropTypes.string,
    agencyId: PropTypes.string,
    agentEmail: PropTypes.string,
    agentId: PropTypes.string,
    agentName: PropTypes.string,
    currencyCode: PropTypes.string,
    customMessage: PropTypes.string,
    phone: PropTypes.string,
  }),
  isAuthenticated: PropTypes.bool,
  onCreatePurlSuccess: PropTypes.func.isRequired,
}

export default AgentDetails
