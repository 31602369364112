import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSpring, useTransition, animated } from '@react-spring/web'
import './tabs-block.scss'

import bookingLogo from '../../assets/icons/booking-logo.svg'
import { snakeCase } from 'lodash'

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const slideWidthAnimationDuration = 1000

const mobileBreakpointWidth = 768 // defined in _variables.scss

const TabsBlock = ({ title, content, height, slides, navTitle }) => {
  // note that for the mobile view, one slide is always in a selected state
  const sliderRef = useRef(null)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const slideRefs = new Array(slides.length).fill(null).map(() => useRef(null))
  const [slidePositions, setSlidePositions] = useState(null)
  const [selectedSlide, setSelectedSlide] = useState(null)
  const [isSlideExpanded, setIsSlideExpanded] = useState(null)
  const [screenWidth, setScreenWidth] = useState()
  const [isMobile, setIsMobile] = useState(false)
  const [audioOn, setAudioOn] = useState(true)

  const selectSlide = index => {
    setIsSlideExpanded(false)
    const moveSlider = e => {
      if (e.propertyName === 'width') {
        requestAnimationFrame(() => {
          sliderRef.current.scroll({
            left: slidePositions[index],
            behavior: 'smooth',
          })
        })
      }
    }

    const moveSliderEnd = e => {
      moveSlider(e)
      if (e.propertyName === 'width') {
        slideRefs[index].current.removeEventListener(
          'transitionstart',
          moveSlider,
        )
        slideRefs[index].current.removeEventListener(
          'transitionrun',
          moveSlider,
        )
        slideRefs[index].current.removeEventListener(
          'transitionend',
          moveSliderEnd,
        )
      }
    }

    slideRefs[index].current.addEventListener('transitionstart', moveSlider)
    slideRefs[index].current.addEventListener('transitionrun', moveSlider)
    slideRefs[index].current.addEventListener('transitionend', moveSliderEnd)

    setSelectedSlide(index)
  }

  const handleBackClick = e => {
    e.stopPropagation()
    setSelectedSlide(null)
  }

  const handleAudioClick = e => {
    e.stopPropagation()
    setAudioOn(!audioOn)
  }

  const handleNextClick = e => {
    e.stopPropagation()
    if (selectedSlide !== null) {
      const nextSlideIndex = selectedSlide + 1
      if (nextSlideIndex === slideRefs.length) {
        selectSlide(0)
      } else {
        selectSlide(nextSlideIndex)
      }
    } else {
      let scrollPosition
      for (let i = 0; i < slidePositions.length; i++) {
        if (
          Math.round(sliderRef.current.scrollLeft) >=
          sliderRef.current.scrollWidth - sliderRef.current.clientWidth
        ) {
          scrollPosition = 0
          break
        }
        if (slidePositions[i] > Math.round(sliderRef.current.scrollLeft)) {
          scrollPosition = slidePositions[i]
          break
        }
      }
      sliderRef.current.scroll({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  const handlePrevClick = e => {
    e.stopPropagation()
    if (selectedSlide !== null) {
      const prevSlideIndex = selectedSlide - 1
      if (prevSlideIndex < 0) {
        selectSlide(slideRefs.length - 1)
      } else {
        selectSlide(prevSlideIndex)
      }
    } else {
      let scrollPosition
      if (Math.round(sliderRef.current.scrollLeft) === 0) {
        scrollPosition = slidePositions[slidePositions.length - 1]
      } else {
        for (let i = 0; i < slidePositions.length; i++) {
          if (slidePositions[i] < Math.round(sliderRef.current.scrollLeft)) {
            scrollPosition = slidePositions[i]
            break
          }
        }
      }
      sliderRef.current.scroll({
        left: scrollPosition,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    // sets the positions to scroll to for the start of each slide
    if (!slidePositions) {
      sliderRef.current.scroll({
        left: 0,
        behavior: 'instant',
      })
      const localSlidePositions = []
      slideRefs.forEach((slide, index) => {
        localSlidePositions[index] = slide.current.offsetLeft
      })
      setSlidePositions(localSlidePositions)
    }
  }, [slideRefs, slidePositions, screenWidth])

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setTimeout(() => {
        setScreenWidth(window.innerWidth)
        setIsMobile(window.innerWidth <= mobileBreakpointWidth)
        setSlidePositions(null)
      }, slideWidthAnimationDuration + 100) // wait for animations to finish
    }, 100)

    window.addEventListener('resize', debouncedHandleResize)
    debouncedHandleResize()

    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])

  useEffect(() => {
    if (isMobile && selectedSlide === null) {
      selectSlide(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  const selectedSlideMobileHeadingTransition = useTransition(
    slides[selectedSlide],
    {
      from: {
        bottom: '-100%',
      },
      enter: {
        bottom: '5%',
      },
      leave: {
        bottom: '-100%',
      },
      config: {
        tension: 80,
        friction: 15,
        mass: 1.5,
      },
    },
  )

  const slidesContentStyle = useSpring({
    top: selectedSlide !== null ? '-100%' : '5%',
    opacity: selectedSlide !== null ? '0' : '1',
    config: {
      tension: 80,
      friction: 20,
      mass: 1.5,
    },
  })

  const selectedSlideTransition = useTransition(slides[selectedSlide], {
    from: {
      opacity: 0,
      bottom: '-100%',
    },
    enter: {
      opacity: 1,
      bottom: '0%',
    },
    leave: {
      opacity: 0,
      bottom: '-100%',
    },
    config: { duration: 700 },
  })

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="TabsBlock">
      <div
        className={`tabs-block ${
          selectedSlide !== null ? 'has-selected-slide' : ''
        }`}
      >
        <animated.div className="slides-content" style={slidesContentStyle}>
          {title && <h1>{title}</h1>}
          {content && <p dangerouslySetInnerHTML={{ __html: content }} />}
        </animated.div>

        <button
          type="button"
          aria-label="Previous Slide"
          className="prev-slide"
          onClick={handlePrevClick}
        ></button>
        <button
          type="button"
          aria-label="Next Slide"
          className="next-slide"
          onClick={handleNextClick}
        />

        <div className="slides" ref={sliderRef}>
          {slides.map((slide, index) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const slideContentStyle = useSpring({
              bottom: selectedSlide === index ? '50%' : '0%',
              opacity: selectedSlide === index ? '0' : '1',
              config: {
                tension: 220,
                friction: 10,
                mass: 1.2,
              },
            })

            const style = {}
            if (slide.backgroundPosition) {
              style.objectPosition = slide.backgroundPosition
            }

            return (
              <div
                key={`slide-${index}`}
                ref={slideRefs[index]}
                className={`slide ${selectedSlide === index ? 'selected' : ''}`}
                onClick={() => selectSlide(index)}
              >
                {slide.placeholderImage ? (
                  <img
                    className="placeholder-image"
                    alt={slide.title}
                    src={slide.placeholderImage}
                  />
                ) : (
                  ''
                )}

                {slide.type === 'video' ? (
                  <video
                    style={style}
                    src={slide.src}
                    playsInline
                    autoPlay
                    muted
                    loop
                  />
                ) : (
                  <img style={style} alt={slide.title} src={slide.src} />
                )}

                {isMobile ? (
                  ''
                ) : (
                  <animated.div
                    className="slide-text"
                    style={slideContentStyle}
                  >
                    <button>
                      {slide?.subtitle && (
                        <div
                          className="slide-subheader"
                          dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                        ></div>
                      )}
                      <h3 dangerouslySetInnerHTML={{ __html: slide.title }} />
                    </button>
                  </animated.div>
                )}
              </div>
            )
          })}
        </div>

        {isMobile
          ? selectedSlide !== null && (
              <div
                className={`slide-text-focused-mobile ${
                  isSlideExpanded ? 'open' : ''
                }`}
              >
                {selectedSlideMobileHeadingTransition((style, slide) => (
                  <animated.div
                    className="slides-content-mobile-heading"
                    style={style}
                  >
                    {slide?.subtitle && (
                      <div
                        className="slide-subheader"
                        dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                      ></div>
                    )}
                    <h3 dangerouslySetInnerHTML={{ __html: slide.title }} />
                    {slide.content && (
                      <button
                        className={`slide-content-toggle ${
                          isSlideExpanded ? 'open' : ''
                        }`}
                        onClick={() => setIsSlideExpanded(!isSlideExpanded)}
                      >
                        {isSlideExpanded ? 'Read Less' : 'Read More'}
                      </button>
                    )}
                    {isSlideExpanded && (
                      <div className="slides-content-mobile-copy" style={style}>
                        <p
                          dangerouslySetInnerHTML={{ __html: slide.content }}
                        />
                      </div>
                    )}
                  </animated.div>
                ))}
              </div>
            )
          : selectedSlideTransition((style, slide) => {
              return (
                slide !== null && (
                  <animated.div
                    className={`slide-text-focused ${
                      isSlideExpanded ? 'open' : ''
                    }`}
                    style={style}
                  >
                    <article>
                      {slide?.subtitle && (
                        <div
                          className="slide-subheader"
                          dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                        ></div>
                      )}
                      <div className="slide-title">
                        <h3 dangerouslySetInnerHTML={{ __html: slide.title }} />
                        {slide.content && (
                          <button
                            className={`slide-content-toggle ${
                              isSlideExpanded ? 'open' : ''
                            }`}
                            onClick={() => setIsSlideExpanded(!isSlideExpanded)}
                          >
                            {isSlideExpanded ? 'Read Less' : 'Read More'}
                          </button>
                        )}
                      </div>

                      {slide.content && isSlideExpanded && (
                        <div
                          className="text-content"
                          dangerouslySetInnerHTML={{ __html: slide.content }}
                        />
                      )}
                      <button
                        type="button"
                        className="text-only back-button"
                        onClick={handleBackClick}
                      >
                        back
                      </button>
                    </article>

                    {slide.video && (
                      <div>
                        <button
                          type="button"
                          className={`text-only ${
                            audioOn ? 'audio-on' : 'audio-off'
                          }`}
                          onClick={handleAudioClick}
                        >
                          <img src={bookingLogo} alt="audio" />
                          <span>Audio On</span>
                        </button>
                      </div>
                    )}
                  </animated.div>
                )
              )
            })}
      </div>
    </section>
  )
}

TabsBlock.propTypes = {
  content: PropTypes.string,
  height: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  navTitle: PropTypes.string,
}

export default TabsBlock
