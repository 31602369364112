import PropTypes from 'prop-types'
import Background from '../background/Background'
import GradientText from '../gradientText/GradientText'
import './double-content-block.scss'
import { snakeCase } from 'lodash'
import { useRef } from 'react'
import useBindRelativeLinks from '../../hooks/useBindRelativeLink'

const DoubleContentBlock = ({
  headerTitle,
  headerTitleColor,
  headerSubTitle,
  headerSubTitleColor,
  headerContent,
  footerTitle,
  footerTitleColor,
  footerSubTitle,
  footerSubTitleColor,
  footerContent,
  height,
  content,
  background,
  borderTopColor,
  linkData,
  navTitle,
}) => {
  const contentRef = useRef(null)
  useBindRelativeLinks(contentRef)

  return (
    <section
      id={navTitle && snakeCase(navTitle)}
      data-type="DoubleContentBlock"
    >
      <Background {...background} height={height}>
        {borderTopColor && (
          <div
            className="border-top"
            style={{ background: borderTopColor }}
          ></div>
        )}
        <div className="double-content-block">
          <div className="header">
            {headerTitle && (
              <GradientText type="h1" color={headerTitleColor}>
                <span dangerouslySetInnerHTML={{ __html: headerTitle }}></span>
              </GradientText>
            )}
            {headerSubTitle && (
              <GradientText type="h2" color={headerSubTitleColor}>
                <span
                  dangerouslySetInnerHTML={{ __html: headerSubTitle }}
                ></span>
              </GradientText>
            )}
            {headerContent && (
              <div
                ref={contentRef}
                className="content"
                dangerouslySetInnerHTML={{ __html: headerContent }}
              />
            )}
          </div>
          <div className="footer">
            {footerTitle && (
              <GradientText type="h1" color={footerTitleColor}>
                {footerTitle}
              </GradientText>
            )}
            {footerSubTitle && (
              <GradientText type="h2" color={footerSubTitleColor}>
                {footerSubTitle}
              </GradientText>
            )}
            {footerContent && (
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: footerContent }}
              />
            )}
          </div>
        </div>
      </Background>
    </section>
  )
}

DoubleContentBlock.propTypes = {
  background: PropTypes.shape({}),
  borderTopColor: PropTypes.string,
  content: PropTypes.string,
  footerContent: PropTypes.string,
  footerSubTitle: PropTypes.string,
  footerSubTitleColor: PropTypes.string,
  footerTitle: PropTypes.string,
  footerTitleColor: PropTypes.string,
  headerContent: PropTypes.string,
  headerSubTitle: PropTypes.string,
  headerSubTitleColor: PropTypes.string,
  headerTitle: PropTypes.string,
  headerTitleColor: PropTypes.string,
  height: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  linkData: PropTypes.shape({
    target: PropTypes.string,
    text: PropTypes.string,
  }),
  navTitle: PropTypes.string,
}

export default DoubleContentBlock
