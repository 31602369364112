const cancelationChargeAmounts = {
  AUD: 100,
  CAD: 100,
  NZD: 100,
  USD: 100,
  CNY: 500,
  DKK: 2000,
  EUR: 80,
  GBP: 75,
  HKD: 600,
  MXN: 1270,
}

export default cancelationChargeAmounts
