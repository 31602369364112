import PropTypes from 'prop-types'
import { useRef } from 'react'
import { snakeCase } from 'lodash/string'
import useBindRelativeLinks from '../../hooks/useBindRelativeLink'
import Background from '../background/Background'
import GradientText from '../gradientText/GradientText'

import './hero.scss'

const Hero = ({
  background,
  title,
  subTitle,
  height,
  logo,
  content,
  titleColor,
  subTitleColor,
  navTitle,
}) => {
  const contentRef = useRef(null)
  useBindRelativeLinks(contentRef)
  return (
    <section data-type="Hero">
      <div
        className="hero white"
        id={navTitle?.length ? snakeCase(navTitle) : 'iconOfTheSeas'}
      >
        <Background {...background} height={height}>
          <div className="content">
            {logo && (
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
            )}
            <div className="copy">
              {title && (
                <GradientText type="h1" color={titleColor}>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </GradientText>
              )}
              {subTitle && (
                <GradientText type="h2" color={subTitleColor}>
                  <span dangerouslySetInnerHTML={{ __html: subTitle }} />
                </GradientText>
              )}
              {content && (
                <div
                  ref={contentRef}
                  className="copy-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </div>
          </div>
        </Background>
      </div>
    </section>
  )
}

Hero.propTypes = {
  background: PropTypes.shape({
    backgroundPosition: PropTypes.string,
    placeholder: PropTypes.string,
    src: PropTypes.string,
    type: PropTypes.string,
  }),
  content: PropTypes.string,
  height: PropTypes.string,
  logo: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  navTitle: PropTypes.string,
}

export default Hero
