import { startCase } from 'lodash'
import PropTypes from 'prop-types'
import './itinerary-table.scss'

const ItineraryTable = ({ days = [], image }) => {
  const featuredDay = days?.find(day => day?.isFeatured)
  const featuredImage = featuredDay ? featuredDay?.featuredImage : image
  return (
    <div className="itinerary-table">
      <h3>Sailing Itinerary</h3>
      <div className="image">
        <img src={featuredImage} alt="Destination" />
      </div>
      <div className="header">
        <p className="day">Day</p>
        <p className="name">Port City</p>
        <p className="activity">Activity</p>
        <p className="arrival-time">Arrival</p>
        <p className="departure-time">Departure</p>
      </div>
      {days.map(day => (
        <div key={day.displayDate} className="row">
          <div className="cell day-cell">
            <div className="label">Day:</div>
            <p className="day">{day.displayDate || ''}</p>
          </div>
          <div className="cell name-cell">
            <div className="label">Port City:</div>
            <p className="name">{day.name || ''}</p>
          </div>
          <div className="cell">
            <div className="label">Activity:</div>
            <p className="activity">
              {startCase(day?.activity?.toLowerCase()) || '-'}
            </p>
          </div>
          <div className="cell">
            <div className="label">Arrival:</div>
            <p className="arrival-time">{day.arrivalTime || '-'}</p>
          </div>

          <div className="cell">
            <div className="label">Departure:</div>
            <p className="departure-time">{day.departureTime || '-'}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
ItineraryTable.propTypes = {
  days: PropTypes.arrayOf(PropTypes.shape({})),
  image: PropTypes.string,
}

export default ItineraryTable
