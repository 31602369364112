import './full-screen-section.scss'
import PropTypes from 'prop-types'

const FullScreenSection = ({
  children,
  backgroundSrc,
  backgroundPlaceholder,
  backgroundType,
  focusPoints,
  accountForHeader,
  hasGradientBg,
  videoId,
}) => {
  if (backgroundType === 'video') {
    return (
      <div
        className={`full-screen-section ${
          accountForHeader ? 'account-for-header' : ''
        }`}
      >
        <video
          playsInline
          autoPlay
          muted
          loop
          id={videoId}
          poster={backgroundPlaceholder}
        >
          <source src={backgroundSrc} type="video/mp4" />
        </video>
        {hasGradientBg && <div className="gradient-bg"></div>}
        {children}
      </div>
    )
  }

  return (
    <div
      className={`full-screen-section ${
        accountForHeader ? 'account-for-header' : ''
      }`}
      style={{
        backgroundImage: `url(${backgroundSrc})`,
        backgroundPosition: focusPoints || '50% 50%',
      }}
    >
      {hasGradientBg && <div className="gradient-bg"></div>}
      {children}
    </div>
  )
}

FullScreenSection.defaultProps = {
  backgroundPlaceholder: '',
  backgroundType: 'image',
  focusPoints: '50% 50%',
  videoId: 'video-id',
}

FullScreenSection.propTypes = {
  accountForHeader: PropTypes.bool,
  backgroundPlaceholder: PropTypes.string,
  backgroundSrc: PropTypes.string.isRequired,
  backgroundType: PropTypes.string,
  children: PropTypes.node.isRequired,
  focusPoints: PropTypes.string,
  hasGradientBg: PropTypes.bool,
  videoId: PropTypes.string,
}

export default FullScreenSection
