import PropTypes from 'prop-types'

const SocialIcon = ({ name }) => {
  switch (name) {
    case 'youtube':
      return (
        <svg
          width="25"
          height="18"
          viewBox="0 0 25 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.441 13.6351V4.95045C24.441 4.95045 24.441 0.764648 20.2552 0.764648H4.33517C4.33517 0.764648 0.151367 0.764648 0.151367 4.95045V13.6351C0.151367 13.6351 0.151367 17.8209 4.33517 17.8209H20.2552C20.2552 17.8209 24.441 17.8209 24.441 13.6351ZM17.0132 9.30495L9.06117 13.9752V4.63335L17.0132 9.30495Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'instagram':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.2912 0.292969H7.59065C3.64827 0.292969 0.440918 3.50032 0.440918 7.4427V17.1432C0.440918 21.0856 3.64827 24.293 7.59065 24.293H17.2912C21.2336 24.293 24.4409 21.0856 24.4409 17.1432V7.4427C24.4409 3.50032 21.2335 0.292969 17.2912 0.292969ZM22.0265 17.1432C22.0265 19.7585 19.9064 21.8786 17.2912 21.8786H7.59065C4.97542 21.8786 2.85532 19.7585 2.85532 17.1432V7.4427C2.85532 4.82743 4.97542 2.70737 7.59065 2.70737H17.2912C19.9064 2.70737 22.0265 4.82743 22.0265 7.4427V17.1432Z"
            fill="currentColor"
          />
          <path
            d="M12.441 5.84766C8.87813 5.84766 5.97949 8.7463 5.97949 12.3092C5.97949 15.872 8.87813 18.7707 12.441 18.7707C16.0039 18.7707 18.9025 15.8721 18.9025 12.3092C18.9025 8.74626 16.0039 5.84766 12.441 5.84766ZM12.441 16.2574C10.2605 16.2574 8.4928 14.4898 8.4928 12.3092C8.4928 10.1286 10.2605 8.36097 12.441 8.36097C14.6216 8.36097 16.3892 10.1286 16.3892 12.3092C16.3892 14.4897 14.6215 16.2574 12.441 16.2574Z"
            fill="currentColor"
          />
          <path
            d="M18.9028 7.67668C19.9224 7.67668 20.7489 6.85013 20.7489 5.83053C20.7489 4.81093 19.9224 3.98438 18.9028 3.98438C17.8832 3.98438 17.0566 4.81093 17.0566 5.83053C17.0566 6.85013 17.8832 7.67668 18.9028 7.67668Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'tiktok':
      return (
        <svg
          width="20"
          height="24"
          viewBox="0 0 20 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5142 0.649414C12.0542 0.649414 13.5582 0.649414 15.0732 0.649414C15.0962 1.05341 15.1042 1.45341 15.1462 1.85041C15.2092 2.44741 15.3632 3.02141 15.6862 3.53541C16.2362 4.40941 17.0592 4.79041 18.0652 4.80641C18.4012 4.81141 18.7372 4.76841 19.1012 4.74441C19.1042 4.79441 19.1142 4.86941 19.1142 4.94441C19.1152 6.32041 19.1102 7.69741 19.1192 9.07341C19.1202 9.26641 19.0682 9.33041 18.8732 9.35541C17.6662 9.51141 16.5022 9.33741 15.3642 8.93341C15.2902 8.90741 15.2162 8.87941 15.0972 8.83541C15.0972 8.95041 15.0972 9.04142 15.0972 9.13241C15.0972 11.6274 15.0982 14.1224 15.0972 16.6184C15.0952 20.2754 12.3702 23.3854 8.74623 23.8694C4.69523 24.4114 1.00423 21.5564 0.497227 17.4894C0.00622737 13.5504 2.78023 9.91241 6.71023 9.35041C7.63423 9.21841 8.55523 9.25641 9.46723 9.47141C9.58523 9.49941 9.64723 9.53442 9.64723 9.67241C9.64323 11.2974 9.64423 12.9234 9.64423 14.5804C9.57923 14.5404 9.53523 14.5174 9.49523 14.4874C8.60323 13.8094 7.62923 13.6634 6.61523 14.1364C5.59123 14.6144 5.06623 15.4644 5.02723 16.5914C4.98023 17.9484 6.10023 19.2064 7.44623 19.3364C8.90123 19.4774 10.2112 18.5214 10.4562 17.1344C10.4892 16.9454 10.5042 16.7504 10.5042 16.5594C10.5072 12.7204 10.5052 8.88041 10.5062 5.04141C10.5062 3.66541 10.5112 2.28841 10.5132 0.912414C10.5142 0.832414 10.5142 0.753414 10.5142 0.649414Z"
            fill="currentColor"
          />
        </svg>
      )
    case 'pinterest':
      return (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3881 0.299208C5.61354 0.299208 0.119141 5.66801 0.119141 12.2932C0.119141 17.2049 3.14114 21.4224 7.46404 23.2742C7.42853 22.4414 7.45789 21.4353 7.67711 20.5225C7.91272 19.5498 9.25471 13.9864 9.25471 13.9864C9.25471 13.9864 8.86339 13.2202 8.86339 12.0915C8.86339 10.3112 9.91779 8.98531 11.2297 8.98531C12.3449 8.98531 12.8851 9.80416 12.8851 10.7849C12.8851 11.8829 12.1687 13.5199 11.8013 15.039C11.494 16.3116 12.4535 17.351 13.7367 17.351C16.0621 17.351 17.6273 14.4327 17.6273 10.9761C17.6273 8.34771 15.8155 6.38151 12.5224 6.38151C8.80109 6.38151 6.48259 9.09261 6.48259 12.1221C6.48259 13.1668 6.7981 13.9064 7.29049 14.4727C7.5179 14.7392 7.54932 14.8458 7.46805 15.1456C7.40864 15.3655 7.27342 15.8985 7.21742 16.1051C7.13547 16.4115 6.88483 16.5182 6.60347 16.4049C4.88937 15.7253 4.09167 13.8864 4.09167 11.8256C4.09167 8.41829 7.02967 4.33139 12.8591 4.33139C17.5433 4.33139 20.6253 7.64679 20.6253 11.2033C20.6253 15.9118 17.9503 19.4231 14.005 19.4231C12.6815 19.4231 11.4351 18.7235 11.0097 17.9307C11.0097 17.9307 10.2967 20.6891 10.1458 21.2287C9.88694 22.1481 9.37679 23.0743 8.91107 23.7938C10.014 24.1136 11.1805 24.2868 12.3879 24.2868C19.1632 24.2868 24.6549 18.9167 24.6549 12.2928C24.6549 5.66813 19.1628 0.298828 12.3879 0.298828"
            fill="currentColor"
          />
        </svg>
      )
    case 'facebook':
      return (
        <svg
          width="12"
          height="25"
          viewBox="0 0 12 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 8.1302H2.5412V5.6607C2.5412 4.5707 2.56852 2.8907 3.35935 1.85C4.1932 0.7484 5.33715 0 7.30535 0C10.5117 0 11.8618 0.45688 11.8618 0.45688L11.2261 4.22318C11.2261 4.22318 10.1676 3.91586 9.17935 3.91586C8.19047 3.91586 7.30535 4.2703 7.30535 5.25846V8.13016H11.3586L11.0758 11.8084H7.30542V24.5854H2.54132V11.8084H0.000119999V8.13016"
            fill="currentColor"
          />
        </svg>
      )
    case 'twitter':
      return (
        <svg
          width="26"
          height="21"
          viewBox="0 0 26 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.1509 2.76005C24.2569 3.1561 23.2962 3.42395 22.2888 3.54405C23.3191 2.92738 24.1091 1.95175 24.4795 0.788552C23.516 1.35935 22.4514 1.77361 21.3138 1.99555C20.4076 1.02736 19.1109 0.422852 17.6772 0.422852C14.9265 0.422852 12.6952 2.65405 12.6952 5.40555C12.6952 5.79688 12.7377 6.17606 12.824 6.54035C8.68343 6.33187 5.01173 4.35025 2.55203 1.33365C2.12292 2.07177 1.87868 2.92725 1.87868 3.84085C1.87868 5.56805 2.75849 7.09355 4.09438 7.98825C3.27934 7.96329 2.50948 7.73726 1.83618 7.36618V7.4269C1.83618 9.843 3.55468 11.857 5.83508 12.3131C5.41744 12.4298 4.97821 12.4885 4.52208 12.4885C4.20092 12.4885 3.88854 12.4588 3.58492 12.4001C4.21914 14.3797 6.05772 15.8208 8.23962 15.8593C6.53332 17.1966 4.38372 17.9927 2.05062 17.9927C1.6485 17.9927 1.2511 17.9705 0.861816 17.9239C3.06742 19.3367 5.68662 20.1619 8.49942 20.1619C17.6679 20.1619 22.6784 12.5702 22.6784 5.98492C22.6784 5.76834 22.6744 5.55244 22.6649 5.33991C23.6392 4.63687 24.4859 3.75911 25.1512 2.76051"
            fill="currentColor"
          />
        </svg>
      )
    default:
      return null
  }
}
SocialIcon.propTypes = {
  name: PropTypes.string,
}
export default SocialIcon
