import PropTypes from 'prop-types'
import { useRef } from 'react'
import useBindRelativeLinks from '../../hooks/useBindRelativeLink'
import Background from '../background/Background'
import GradientText from '../gradientText/GradientText'
import './content-block.scss'
import { snakeCase } from 'lodash/string'
const ContentBlock = ({
  title,
  subTitle,
  titleColor,
  subTitleColor,
  height,
  content,
  background,
  borderTopColor,
  borderBottomColor,
  navTitle,
}) => {
  const contentRef = useRef(null)
  useBindRelativeLinks(contentRef)

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="ContentBlock">
      <Background {...background} height={height}>
        {borderTopColor && (
          <div
            className="border-top"
            style={{ background: borderTopColor }}
          ></div>
        )}
        <div className="content-block">
          {title && (
            <GradientText type="h1" color={titleColor}>
              <span dangerouslySetInnerHTML={{ __html: title }} />
            </GradientText>
          )}
          {subTitle && (
            <GradientText type="h2" color={subTitleColor}>
              <span dangerouslySetInnerHTML={{ __html: subTitle }} />
            </GradientText>
          )}
          {content && (
            <div
              ref={contentRef}
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
        {borderBottomColor && (
          <div
            className="border-bottom"
            style={{ background: borderBottomColor }}
          ></div>
        )}
      </Background>
    </section>
  )
}

ContentBlock.propTypes = {
  background: PropTypes.shape({}),
  borderBottomColor: PropTypes.string,
  borderTopColor: PropTypes.string,
  content: PropTypes.string,
  height: PropTypes.string,
  navTitle: PropTypes.string,
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
}

export default ContentBlock
