import SocialIcon from './SocialIcon'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import './social-links.scss'
import PropTypes from 'prop-types'
// const links = [
//   {
//     name: 'youtube',
//     url: 'https://www.youtube.com/watch?v=6-jNHpJr8gA',
//   },
//   {
//     name: 'instagram',
//     url: 'https://www.instagram.com/RoyalCaribbean/',
//   },
//   {
//     name: 'tiktok',
//     url: 'https://www.tiktok.com/@royalcaribbean?lang=en',
//   },
//   {
//     name: 'pinterest',
//     url: 'https://www.pinterest.com/RoyalCaribbean',
//   },
// ]

const SocialLinks = ({ shouldShowLinks = false }) =>
  shouldShowLinks ? (
    <div className="social-links">
      <div className="share-text">Share:</div>
      <div className="social-link">
        <FacebookShareButton url={window.location.href}>
          <SocialIcon name="facebook" />
        </FacebookShareButton>
      </div>
      <div className="social-link">
        <TwitterShareButton url={window.location.href}>
          <SocialIcon name="twitter" />
        </TwitterShareButton>
      </div>
      {/* {links.map(link => (
        <div key={link.name} className="social-link">
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <SocialIcon name={link.name} />
          </a>
        </div>
      ))} */}
    </div>
  ) : null

SocialLinks.propTypes = {
  shouldShowLinks: PropTypes.bool,
}

export default SocialLinks
