import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { throttle } from 'lodash'

import SocialLinks from '../socialLinks/SocialLinks'

import phoneLogo from '../../assets/icons/phone-icon.svg'
import emailLogo from '../../assets/icons/email-icon.svg'

import './header.scss'
import { useNavigate } from 'react-router-dom'

const Header = ({
  isAuthenticated,
  logo,
  showBookingLink,
  agentData,
  menuItems,
  showSocialLinks = false,
  goToVideo,
  ssoParam,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const scrolledRef = useRef(null)
  const tabRef = useRef(null)

  useEffect(() => {
    if (isOpen) {
      tabRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    let currentRef
    let observer

    const handler = ([entry]) => {
      setScrolled(!entry.isIntersecting)
    }

    const validateScroll = throttle(handler, 250, { leading: true })

    if (scrolledRef.current) {
      observer = new IntersectionObserver(validateScroll)
      observer.observe(scrolledRef.current)
      currentRef = scrolledRef.current
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [scrolledRef])
  const toggleMenu = () => setIsOpen(!isOpen)
  const [scrolled, setScrolled] = useState(false)

  const navigate = useNavigate()

  function handleLinkClick(e) {
    setIsOpen(false)
    e.preventDefault()
    const url = new URL(e.target.href)
    const href = url.toString().substr(url.origin.length)
    navigate(href)
    setTimeout(() => {
      const a = document.querySelector(`[id=${url.hash.substr(1)}]`)
      a.scrollIntoView({ behavior: 'instant' })
    })
  }

  function appendQueryString(href, queryParams) {
    let url, newHref
    try {
      url = new URL(href)
      newHref = href
    } catch {
      // url is relative
      url = new URL(href, window.location.origin)
      const queryString = Object.keys(queryParams)
        .map(param => `${param}=${queryParams[param]}`)
        .join('&')
      if (url.pathname === '/') {
        newHref = `/?${queryString}`
      } else {
        newHref = `/?p=${url.pathname}&${queryString}`
      }
      if (url.hash) {
        newHref += url.hash
      }
      return newHref
    }
  }

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
        }}
        ref={scrolledRef}
      />
      <header className={scrolled ? 'scrolled' : ''} id={'header'}>
        <div className="menu-items">
          <div className="menu-left">
            <button
              className="hamburger-container"
              aria-label="toggle menu"
              onClick={toggleMenu}
            >
              <div />
              <div />
              <div />
            </button>
            {showSocialLinks && (
              <SocialLinks shouldShowLinks={showSocialLinks} />
            )}
          </div>
          <div className={`logo-container`}>
            {logo && <img src={logo} alt="" />}
          </div>
          {showBookingLink && (
            <div className="cta-container">
              <a href={'#book'}>{`${
                isAuthenticated
                  ? 'Share with your clients'
                  : 'Start Planning Your Vacation'
              }`}</a>
            </div>
          )}
        </div>

        {agentData && (
          <div className="top-agent-info">
            <div className="agent-contents-section">
              <p>
                agent name:{' '}
                <span className="content">{agentData.agentName}</span>
              </p>
              <p>
                agency: <span className="content">{agentData.agency}</span>
              </p>
              <p>
                email:{' '}
                <a
                  href={`mailto:${agentData.agentEmail}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {agentData.agentEmail}
                </a>
              </p>
              <p>
                phone:{' '}
                <a
                  href={`tel:${agentData.phone}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {agentData.phone}
                </a>
              </p>
            </div>
            <div className="icon-section">
              <a
                href={`tel:${agentData.phone}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src={phoneLogo} alt="phone-icon"></img>
              </a>
              <a
                href={`mailto:${agentData.agentEmail}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {' '}
                <img src={emailLogo} alt="email-icon"></img>
              </a>
            </div>
          </div>
        )}
      </header>
      <div className={`menu-drawer ${isOpen ? 'active' : ''}`}>
        <div className="menu-drawer-content">
          <div>
            <button ref={tabRef} onClick={toggleMenu} aria-label="toggle menu">
              x Close
            </button>
            <nav>
              <ul className="links">
                {menuItems.map(link => (
                  <li key={link.target}>
                    <a
                      href={appendQueryString(link.target, { sso: ssoParam })}
                      onClick={handleLinkClick}
                    >
                      {link.text}
                      {link.new && <span className="new">New</span>}
                    </a>
                  </li>
                ))}
                <li className="last-link" onClick={goToVideo}>
                  Replay Video
                </li>
              </ul>
            </nav>
          </div>
          {showBookingLink && (
            <div className="drawer-footer">
              <div className="cta-container">
                {isAuthenticated && (
                  <a className="uppercase" href={'#book'} onClick={toggleMenu}>
                    Share With Your Clients
                  </a>
                )}
                {!isAuthenticated && (
                  <a className="uppercase" href={'#book'} onClick={toggleMenu}>
                    Start Planning Your Vacation
                  </a>
                )}
              </div>
              <SocialLinks shouldShowLinks={showSocialLinks} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

Header.defaultProps = {
  logo: null,
  scrolled: false,
}

Header.propTypes = {
  agentData: PropTypes.shape({
    agency: PropTypes.string,
    agentEmail: PropTypes.string,
    agentName: PropTypes.string,
    phone: PropTypes.string,
  }),
  goToVideo: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  logo: PropTypes.string,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  scrolled: PropTypes.bool,
  showBookingLink: PropTypes.bool.isRequired,
  showSocialLinks: PropTypes.bool,
  ssoParam: PropTypes.string,
}

export default Header
