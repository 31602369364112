import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

const Room = ({ room, handleBackClick }) => {
  const videoRef = useRef()
  const [videoComplete, setVideoComplete] = useState(false)
  const features = room?.features || []

  function handleVideoEnded() {
    setVideoComplete(true)
  }

  return (
    <div className="room">
      <div className="content-wrapper">
        <article>
          <div className="content">
            <h2 dangerouslySetInnerHTML={{ __html: room.title }} />
            <p dangerouslySetInnerHTML={{ __html: room.content }} />

            <div className="features">
              {features.map((feature, index) => (
                <div key={`feature-${index}`}>
                  <img src={feature.image} alt="icon" />
                  <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                </div>
              ))}
            </div>
            <div className="actions">
              <button type="button" onClick={() => handleBackClick()}>
                back
              </button>
            </div>
          </div>
        </article>

        <div className="background">
          <img src={room?.image} alt={room?.title} />
        </div>
        {room?.video && (
          <div
            className={`background-video ${videoComplete ? 'complete' : ''}`}
          >
            <video
              ref={videoRef}
              autoPlay
              playsInline
              muted
              id="stateroom-video"
              onEnded={handleVideoEnded}
            >
              <source src={room?.video} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
    </div>
  )
}

Room.propTypes = {
  handleBackClick: PropTypes.func.isRequired,
  room: PropTypes.shape({
    content: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
    image: PropTypes.string,
    title: PropTypes.string,
    video: PropTypes.string,
  }),
}

export default Room
