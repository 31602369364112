// medium = 300
// medium_large = 768
// large = 1024
// gallery-views = 1300
// details-hero = 1920
const generateImageSourceSets = images => {
  return [
    {
      src: images?.['medium'],
      width: images?.['medium-width'],
      height: images?.['medium-height'],
      minWidth: 0,
      maxWidth: 300,
    },

    {
      src: images?.['medium_large'],
      width: images?.['medium_large-width'],
      height: images?.['medium_large-height'],
      minWidth: 300,
      maxWidth: 768,
    },
    {
      src: images?.['large'],
      width: images?.['large-width'],
      height: images?.['large-height'],
      minWidth: 768,
      maxWidth: 1024,
    },
    {
      src: images?.['gallery-views'],
      width: images?.['gallery-views-width'],
      height: images?.['gallery-views-height'],
      minWidth: 1024,
      maxWidth: 1300,
    },
    {
      src: images?.['details-hero'],
      width: images?.['details-hero-width'],
      height: images?.['details-hero-height'],
      minWidth: 1300,
      maxWidth: 1920,
    },
  ].filter(image => !!image.src)
}

export default generateImageSourceSets
