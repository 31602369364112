import generateImageSourceSets from './generateImageSourceSets'
import generateBackgroundPosition from './generateBackgroundPosition'

const formatSlides = (slides, srcTarget = 'details-hero') => {
  return slides?.length
    ? slides
        .map((slide, i) => {
          const video = slide?.video?.url || null
          const image = slide.image?.sizes?.[srcTarget]
          return {
            key: `${slide?.image?.id}-${i}`,
            type: video ? 'video' : 'image',
            src: video || image,
            title: slide.heading,
            subtitle: slide?.subheading,
            content: slide.content,
            srcSet: generateImageSourceSets(slide?.image?.sizes),
            placeholderImage: slide?.placeholder_image?.url,
            ...generateBackgroundPosition(slide),
          }
        })
        .filter(slide => !!slide.src)
    : []
}

export default formatSlides
