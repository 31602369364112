import PropTypes from 'prop-types'
import './why-we-ask.scss'
const WhyWeAsk = ({ onCloseClick }) => {
  return (
    <div className="why-we-ask">
      <div className="title">
        <h1>Why we ask</h1>
        <div>
          <button onClick={onCloseClick}>Close</button>
        </div>
      </div>
      <div className="content">
        <div>
          <h4>
            <strong>Age 55+</strong>
          </h4>
          <p>
            Many Cruise Lines offer special rates for guests 55 years of age or
            older. If any guest in your stateroom is 55 years of age or older by
            the date the cruise departs, these special rates and additional
            savings may apply.
          </p>
          <p>
            Please note: any guest who qualifies for an age specific special
            rate will be required to show a government-issued ID (passport, or
            birth certificate and driver's license) at check-in to validate
            their age prior to boarding.
          </p>
        </div>
        <div>
          <h4>Military Program Restrictions</h4>
          <p>
            You are purchasing a cruise vacation using a special restricted
            price program rate. It is important that at least one guest per
            stateroom be eligible to be considered in compliance with a
            restricted price program. It is also important to note that
            non-compliance of the restricted price program terms will result in
            the reservation being re-priced to the lowest available fare for
            that particular day. Furthermore, you will be responsible to pay for
            any differences immediately or else your boarding rights will be
            denied.
          </p>
          <p>
            To qualify for a military rate, an individual must be classified as
            one of the following: Active personnel currently serving in the
            Canadian National Defense; United States Army, Navy, Air Force,
            Marines, Coast Guard, National Guard or Reserves. Proper
            identification in the form of a United States Uniformed Services
            WHITE Identification Card for U.S. military personnel, and a WHITE
            N.D.21 Identification Card with a Red Canadian Flag displayed for
            Canadian National Defense personnel, must be presented at time of
            check-in.
          </p>
          <p>
            Retired from any of the divisions listed directly above. "Retired"
            is defined as:
          </p>
          <ul>
            <li>
              <p>
                A) enlisted personnel or officers with a minimum of 20 years of
                service,
              </p>
            </li>
            <li>
              <p>B) medically retired, or </p>
            </li>
            <li>
              <p>C) 100% disabled.</p>
            </li>
          </ul>
          <p>
            Proper identification for those classified in category A or B must
            be in the form of a United States Uniformed RED (Reserve retired
            personnel) or BLUE (Active Duty retired personnel) Identification
            Card. Proper identification for those classified in category C is a
            United States Uniformed TAN Identification Card. Retired Canadian
            National Defense personnel are eligible and must possess the same
            white card as described above for active personnel. All forms of
            identification must be presented at time of check-in.
          </p>
          <p>
            Veterans with an Honorable Discharge serving a minimum of 2 years,
            or 6-months in an active war zone, in any of the United States
            service divisions listed above. Proper identification in the form of
            a DD214 or DD256, plus a current photo must be presented at time of
            check-in.
          </p>
          <p>
            Spouses of actively deployed, or deceased military personnel, are
            eligible to book one stateroom as long as the eligible spouse is
            occupying the stateroom. All other guests traveling in the same
            stateroom are also eligible for the military rate. Spouse must
            present the PINK Dependent Military ID upon check-in as proof of
            eligibility.
          </p>
          <p>
            All guests traveling in the same stateroom are eligible to receive
            the military rate. No additional staterooms, other than the one that
            is occupied by the military person is eligible for the military
            rate. Spouses, parents, in-laws, children or other family members or
            friends are not eligible for the military rate without the eligible
            military person occupying the stateroom.
          </p>
          <p>
            Please Note: A valid driver's license or state-issued ID; U.S. or
            Canadian military ID; or U.S. or Canadian local, state or federal
            law enforcement, fire department, or EMT employment badge or ID must
            be presented at the pier to validate eligibility for the applicable
            Exclusive Rate. If eligibility cannot be validated, you will be
            charged the standard rate or denied boarding.
          </p>
        </div>
        <div>
          <h4>
            Law Enforcement Agency or Fire Department Program Restrictions.
          </h4>
          <p>
            This is a special and restricted price program rate. It is important
            that at least one guest per stateroom be eligible to be considered
            in compliance with a restricted price program. It is also important
            to note that noncompliance of the restricted price program terms
            will result in the reservation being re-priced to the lowest
            available fare for that particular day. Furthermore, you will be
            responsible to pay for any differences immediately or else your
            boarding rights will be denied.
          </p>
          <p>
            To qualify for a police/fire department rate, an individual must
            qualify under the following: At least one guest in the stateroom
            must be an active employee of a local, state or federal law
            enforcement agency or fire department in the United States.
            Qualified guests must present valid proof of employment (badge or
            ID) plus a second form of identification when boarding the ship. The
            offer is not valid where restricted by applicable law.
          </p>
          <p>
            All guests traveling in the same stateroom are eligible to receive
            the police/fire rate. No additional staterooms, other than the one
            that is occupied by the qualified person is eligible for the
            police/fire rate. Spouses, parents, in-laws, children or other
            family members or friends are not eligible for the rate without the
            eligible person occupying the stateroom.
          </p>
          <p>
            Please Note: A valid driver's license or state-issued ID; U.S. or
            Canadian military ID; or U.S. or Canadian local, state or federal
            law enforcement, fire department, or EMT employment badge or ID must
            be presented at the pier to validate eligibility for the applicable
            Exclusive Rate. If eligibility cannot be validated, you will be
            charged the standard rate or denied boarding.
          </p>
        </div>
      </div>
    </div>
  )
}

WhyWeAsk.propTypes = {
  onCloseClick: PropTypes.func,
}

export default WhyWeAsk
