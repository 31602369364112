import { camelCase } from 'lodash'
import a from '../../assets/images/accolade_1.png'
import a2 from '../../assets/images/accolade_2.png'
import footerLogo from '../../assets/images/footer_logo.svg'
import convertHexToRGBA from '../../utils/convertHexToRGBA'
import generateBackgroundPosition from '../../utils/generateBackgroundPosition'
import formatSlides from '../../utils/formatSlides'

const formatPageData = data => {
  const linkCutoff = 'icon-of-the-seas/'

  const pages = data.reduce((acc, cur) => {
    // Convert drupal full links to relative paths for react-router
    const path =
      '/' +
      cur.link
        .substring(cur.link.indexOf(linkCutoff) + linkCutoff.length)
        .slice(0, -1)

    acc[path] = {
      title: cur?.title?.rendered,
      menuOrder: cur?.menu_order ?? 0,
      new: cur.acf?.nav_options?.flag_as_new,
      blocks: reduceBlocks(cur?.acf?.page_layouts),
    }

    if (path === '/') {
      acc[path].splashPage = reduceSplashPage(cur?.acf)
    }
    return acc
  }, {})

  const primaryPage = pages['/']

  return {
    pages,
    splashPage: primaryPage?.splashPage,
    // we are going to hard code the footer for now
    footer: {
      privacyLink: {
        text: 'Privacy',
        href: 'https://www.royalcaribbean.com/resources/privacy-policy',
      },
      termsLink: {
        text: 'Terms of Use', // TODO: we need this link
        href: 'https://www.royalcaribbean.com/resources/privacy-policy',
      },
      accolades: [
        {
          img: a,
          alt: 'Best Cruise Line Overall',
        },
        {
          img: a2,
          alt: 'Best Cruise Line Overall',
        },
      ],
      logo: {
        img: footerLogo,
        alt: 'Royal',
      },
    },
  }
}

export default formatPageData

function reduceBlocks(data) {
  const reducers = {
    page_hero: reduceHeroBlock,
    ContentBlock: reduceContentBlock,
    SliderBlock: reduceSliderBlock,
    PanelBlock: reducePanelBlock,
    DoubleContentBlock: reduceDoubleContentBlock,
    ship_sections: reduceShipAreas,
    TabsBlock: reduceTabsBlock,
    RoomsBlock: reduceRoomsBlock,
    VideoBlock: reduceVideoBlock,
    PanelSliderBlock: reducePanelSliderBlock,
  }
  if (data) {
    return data.map(block => {
      const type = block?.acf_fc_layout
      const reducer = reducers[type]
      return reducer ? reducer(block[type]) : block[type]
    })
  }
  return []
}

function reduceHeroBlock(data) {
  return {
    ...blocks.Hero,
    data: {
      ...blocks.Hero.data,
      background: reduceBackground(data),
      height: data?.height,
      logo: data?.logo?.url,
      title: data?.heading,
      titleColor: data?.heading_color,
      subTitle: data?.subheading,
      subTitleColor: data?.subheading_color,
      content: data?.content,
      navTitle: data?.navigation_title,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
    },
  }
}

function reduceVideoBlock(data) {
  return {
    ...blocks.VideoBlock,
    data: {
      ...blocks.VideoBlock.data,
      background: reduceBackground(data),
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
    },
  }
}

function reduceContentBlock(data) {
  return {
    ...blocks.ContentBlock,
    data: {
      ...blocks.ContentBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      titleColor: data?.heading_color,
      subTitle: data?.subheading,
      subTitleColor: data?.subheading_color,
      content: data?.content,
      borderTopColor: data?.top_border_color,
      borderBottomColor: data?.bottom_border_color,
      height: data?.height,
      background: reduceBackground(data),
    },
  }
}

function reducePanelBlock(data) {
  return {
    ...blocks.PanelBlock,
    data: {
      ...blocks.PanelBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      titleColor: data?.heading_color,
      subTitle: data?.subheading,
      subTitleColor: data?.subheading_color,
      content: data?.content,
      borderTopColor: data?.top_border_color,
      height: data?.height,
      panelSide: data?.panel_side,
      panelMaxWidth: data?.panel_max_width,
      panelBackground: data?.panel_background_color,
      edgeMargin: Number(data?.edge_margin),
      background: reduceBackground(data),
    },
  }
}

function reduceSliderBlock(data) {
  return {
    ...blocks.SliderBlock,
    data: {
      ...blocks.SliderBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      titleColor: data?.heading_color,
      subTitle: data?.subheading,
      subTitleColor: data?.subheading_color,
      content: data?.content,
      borderTopColor: data?.top_border_color,
      height: data?.height,
      background: reduceBackground(data),
      slides: data?.slides?.length
        ? formatSlides(data.slides, '1920x1080')
        : [],
    },
  }
}

function reduceDoubleContentBlock(data) {
  return {
    ...blocks.DoubleContentBlock,
    data: {
      ...blocks.DoubleContentBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      headerTitle: data?.heading,
      headerTitleColor: data?.heading_color,
      headerSubTitle: data?.subheading,
      headerSubTitleColor: data?.subheading_color,
      headerContent: data?.heading_content,
      footerTitle: data?.bottom_heading,
      footerTitleColor: data?.bottom_heading_color,
      footerSubTitle: data?.bottom_subheading,
      footerSubTitleColor: data?.bottom_subheading_color,
      footerContent: data?.bottom_content,
      borderTopColor: data?.top_border_color,
      height: data?.height,
      background: reduceBackground(data),
    },
  }
}

function reduceShipAreas(data) {
  return {
    type: 'ShipAreas',
    data: {
      linkData: {
        text: data?.navigation_title || '',
        target: camelCase(data?.navigation_title || ''),
      },
      title: data.heading || '',
      content: data.content || null,
      image: data.image?.url || '',
      mobileImage: data.mobile_image?.url || '',
      height: data.height || 100,
      tiles: data.tiles?.length
        ? data.tiles.map(tile => ({
            ...tile,
            image: tile?.image?.sizes?.['1920x1080'],
            video: tile?.video?.url,
            thumbnail: tile?.image?.sizes?.['email-header'],
            backgroundPosition: generateBackgroundPosition(tile),
          }))
        : [],
    },
  }
}

function reduceTabsBlock(data) {
  return {
    ...blocks.TabsBlock,
    data: {
      ...blocks.TabsBlock.data,
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      titleColor: data?.heading_color,
      content: data?.copy,
      height: data?.height,
      slides: data?.slides?.length
        ? formatSlides(data.slides, '1920x1080')
        : [],
    },
  }
}

function reduceRoomsBlock(data) {
  return {
    ...blocks.RoomsBlock,
    data: {
      ...blocks.RoomsBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      content: data?.content,
      height: data?.height || 95,
      background: reduceBackground(data),
      tiles: data?.tiles?.length
        ? data?.tiles?.map(tile => ({
            title: tile?.heading,
            content: tile?.content,
            features:
              tile?.icons &&
              tile?.icons?.map(iconData => ({
                content: iconData?.text,
                image: iconData?.icon?.url,
              })),
            image: tile?.image?.url,
            video: tile?.video?.url,
            focus: `${tile?.horizontal_focus}% ${tile?.vertical_focus}%`,
            thumbnail: tile?.thumbnail?.url,
          }))
        : [],
    },
  }
}

function reducePanelSliderBlock(data) {
  return {
    ...blocks.PanelSliderBlock,
    data: {
      ...blocks.PanelSliderBlock.data,
      linkData: {
        text: data?.navigation_title,
        target: data?.navigation_title ? camelCase(data?.navigation_title) : '',
      },
      navTitle: data?.navigation_title,
      flagAsNew: data?.flag_as_new,
      title: data?.heading,
      titleColor: data?.heading_color,
      subTitle: data?.subheading,
      subTitleColor: data?.subheading_color,
      content: data?.content,
      borderTopColor: data?.top_border_color,
      height: data?.height,
      background: reduceBackground(data),
      slides: data?.slides?.length
        ? formatSlides(data.slides, '1920x1080')
        : [],
    },
  }
}

function reduceBackground(data) {
  const backgroundImage = data?.image?.url
  const backgroundVideo = data?.video?.url
  const type = backgroundVideo ? 'video' : 'image'
  return {
    color: data?.background_color,
    mobileColor: data?.background_color_mobile || data?.background_color,
    image: backgroundImage,
    video: backgroundVideo,
    videoHeight: data?.video?.height,
    cinemagraph: data?.cinemagraph?.url,
    type,
    loopVideo: data?.loop_video,
    focus: `${data?.horizontal_focus}% ${data?.vertical_focus}%`,
    videoVerticalFocus: data?.video_vertical_focus
      ? `${data?.video_vertical_focus}%`
      : '0%',
  }
}

/**
 * Block shapes for reference / defaults
 */
const blocks = {
  Hero: {
    type: 'Hero',
    data: {
      title: '',
      titleColor: '',
      subTitle: '',
      subTitleColor: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      height: 100,
    },
  },
  ContentBlock: {
    type: 'ContentBlock',
    data: {
      navTitle: '',
      flagAsNew: false,
      title: '',
      titleColor: '',
      subTitle: '',
      subTitleColor: '',
      content: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      borderTopColor: '',
      height: 140,
    },
  },
  DoubleContentBlock: {
    type: 'DoubleContentBlock',
    data: {
      headerTitle: '',
      headerTitleColor: '',
      headerSubTitle: '',
      headerSubTitleColor: '',
      headerContent: '',
      footerTitle: '',
      footerTitleColor: '',
      footerSubTitle: '',
      footerSubTitleColor: '',
      footerContent: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      borderTopColor: '',
      height: 250,
    },
  },
  PanelBlock: {
    type: 'PanelBlock',
    data: {
      title: '',
      titleColor: '',
      content: '',
      panelSide: 'left', // left | right
      panelBackground: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      borderTopColor: '',
      height: 100,
    },
  },
  SliderBlock: {
    type: 'SliderBlock',
    data: {
      title: '',
      titleColor: '',
      subTitle: '',
      subTitleColor: '',
      content: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      borderTopColor: '',
      height: 250,
      arrowColor: '',
      slides: [],
    },
  },
  TabsBlock: {
    type: 'TabsBlock',
    data: {
      title: '',
      titleColor: '',
      content: '',
      height: 100,
      slides: [],
    },
  },
  RoomsBlock: {
    type: 'RoomsBlock',
    data: {
      title: '',
      content: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      height: 100,
      tiles: [],
    },
  },
  ShipAreas: {
    type: 'ShipAreas',
    data: {
      title: '',
    },
  },
  VideoBlock: {
    type: 'VideoBlock',
    data: {
      navTitle: '',
      flagAsNew: false,
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      height: 100,
    },
  },
  PanelSliderBlock: {
    type: 'PanelSliderBlock',
    data: {
      title: '',
      titleColor: '',
      subTitle: '',
      subTitleColor: '',
      content: '',
      background: {
        type: '', // image | video
        src: '',
        color: '',
      },
      borderTopColor: '',
      height: 140,
      arrowColor: '',
      slides: [],
    },
  },
}

function reduceSplashPage(content) {
  return content?.splash_page
    ? {
        title: content?.splash_page?.heading || '',
        subTitle: content?.splash_page?.heading_2 || '',
        icon: content?.splash_page?.icon?.url,
        video: {
          src: content?.splash_page?.video?.url,
          placeholder: '',
        },
        overlay: {
          src: content?.splash_page?.video?.url,
          placeholder: content?.splash_page?.image?.sizes?.['details-hero'],
          ...generateBackgroundPosition(content?.splash_page),
          backgroundColor: convertHexToRGBA(
            content?.splash_page?.background_color || '#000',
            content?.splash_page?.background_opacity_ || '30',
          ),
        },
      }
    : null
}
