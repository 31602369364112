import PropTypes from 'prop-types'
import './loading-spinner.scss'

const LoadingSpinner = ({ color }) => (
  <div
    className={`loading-spinner ${color || ''}`}
    data-testid="loading-spinner"
  >
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
)

LoadingSpinner.propTypes = {
  color: PropTypes.string,
}

export default LoadingSpinner
