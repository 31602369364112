import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { NextArrow, PrevArrow } from '../sliderBlock/SliderNavs'
import Background from '../background/Background'
import GradientText from '../gradientText/GradientText'
import Slide from '../sliderBlock/Slide'
import './panel-slider-block.scss'
import { snakeCase } from 'lodash'

const PanelSliderBlock = ({
  background,
  borderTopColor,
  slides,
  title,
  subTitle,
  titleColor,
  subTitleColor,
  content,
  navTitle,
}) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="PanelSliderBlock">
      <Background {...background}>
        {borderTopColor && (
          <div
            className="border-top"
            style={{ background: borderTopColor }}
          ></div>
        )}
        <div className="panel-slider-block">
          <div className="content-side">
            <div className="content-container">
              {title && (
                <GradientText type="h1" color={titleColor}>
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                </GradientText>
              )}
              {subTitle && (
                <GradientText type="h2" color={subTitleColor}>
                  <span dangerouslySetInnerHTML={{ __html: subTitle }} />
                </GradientText>
              )}
              {content && (
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              )}
            </div>
          </div>
          <div className="slide-side">
            <div className="slide-container">
              {slides?.length > 0 ? (
                <div className="slider">
                  <Slider {...settings}>
                    {slides.map(slide => (
                      <Slide slide={slide} key={slide.key} />
                    ))}
                  </Slider>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </Background>
    </section>
  )
}

PanelSliderBlock.propTypes = {
  background: PropTypes.shape({}),
  borderTopColor: PropTypes.string,
  content: PropTypes.string,
  height: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({})),
  subTitle: PropTypes.string,
  subTitleColor: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  navTitle: PropTypes.string,
}

export default PanelSliderBlock
