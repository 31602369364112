import { get } from 'lodash'
import dayjs from 'dayjs'

import formatPageData from './formatters/formatPageData'

const endpoints = {
  sso: `${process.env.REACT_APP_DOLLI_BASE}/rcl/agent`,
  brands: `${process.env.REACT_APP_DOLLI_BASE}/rcl/content/brands`,
  contactEmail: `${process.env.REACT_APP_DOLLI_BASE}/rcl/contact-email`,
  content: `${process.env.REACT_APP_DOLLI_BASE}/rcl/content/site/icon`,
  countries: `${process.env.REACT_APP_DOLLI_BASE}/rcl/content/countries`,
  cruise: `${process.env.REACT_APP_DOLLI_BASE}/rcl/cruise`,
  destinations: `${process.env.REACT_APP_DOLLI_BASE}/rcl/content/destinations`,
  itinerary: `${process.env.REACT_APP_DOLLI_BASE}/rcl/cruise`,
}

export const getAgentData = sso =>
  fetch(`${endpoints.sso}/${sso}?renewData=${new Date().getTime()}`, {
    method: 'GET',
  })
    .then(res => res.json())
    .then(res => {
      const data = res?.data
      if (!data) {
        return null
      }
      return {
        agentId: data?.agent?.rccl_id,
        agentName: data.agent?.name,
        agentEmail: data.agent?.email,
        agency: data.agency?.name,
        agencyId: data?.agency?.agency_id,
        phone: data.agency?.phone,
        currencyCode: data?.transactionData?.defaultCurrencyCode,
      }
    })

export const getBrandData = () =>
  fetch(endpoints.brands)
    .then(res => res.json())
    .then(res => reduceBrandsData(res))

export const getContent = () =>
  fetch(endpoints.content)
    .then(res => res.json())
    .then(res => formatPageData(res?.data))

export const getNextSailings = ({ currencyCode }) => {
  const firstDate = dayjs().isAfter('2023-05-18')
    ? dayjs()
    : dayjs('2023-05-18')

  const params = `brand=R&bookingType=FIT&cruiseType=CO&ship=IC&startDate=${firstDate.format(
    'YYYY-MM-DD',
  )}&sortBy=SAILDATE&count=16&currency=${currencyCode}`

  return Promise.all([
    fetch(endpoints.destinations).then(res => res.json()),
    fetch(`${endpoints.cruise}?${params}`)
      .then(res => res.json())
      .catch(err => err),
  ]).then(responses =>
    formatNextSailings(
      responses[0],
      responses
        .slice(1)
        ?.map(res => res?.['hydra:member']?.[0]?.packages)
        ?.flat()
        .filter(sailing => !!sailing && sailing?.pricing?.pricing?.[2])
        ?.reduce((acc, cur) => {
          const exists = !!acc.find(
            sailing =>
              sailing.id === cur.id && sailing.sailDate === cur.sailDate,
          )
          if (!exists) {
            acc.push(cur)
          }
          return acc
        }, [])
        ?.sort(function (a, b) {
          return new Date(a.sailDate) - new Date(b.sailDate)
        })
        .slice(0, 4),
    ),
  )
}

export const getStateOptions = () =>
  Promise.all([
    fetch(`${endpoints.countries}/USA`).then(res => res.json()),
    fetch(`${endpoints.countries}/CAN`).then(res => res.json()),
  ]).then(([states, provinces]) => [
    ...(states?.data?.division || []),
    ...(provinces?.data?.division || []),
  ])

export const postEmail = reqBody => postData(endpoints.contactEmail, reqBody)

export const fetchItinerary = ({ id, sailDate }) =>
  fetch(
    `${endpoints.itinerary}/${id}/itinerary?brand=R&sailDate=${sailDate}`,
  ).then(res => res.json())

const reduceBrandsData = brands => {
  if (!brands.length) {
    return null
  }

  const royal = brands.find(brand => brand.brand_code === 'R')

  return {
    logoDark: royal?.badge_dark?.file,
  }
}

const formatNextSailings = (destinations, sailings) => {
  if (!sailings?.length) {
    return []
  }
  return sailings.map(sailing => {
    const {
      description,
      id,
      cruisePort,
      sailDate,
      shipName,
      pricing: { pricing },
      stateroomTypes,
      destinationCode,
      shipCode,
      brand,
      sailingNights,
    } = sailing

    const destination = destinations?.find(
      destination => destination.destination_code === destinationCode,
    )
    const portName = cruisePort?.[0]?.description
    const imagePath = [0, 'sizes', 'itineraries-and-destinations']
    const image =
      get(destination, ['image_r', ...imagePath]) ||
      get(destination, ['image', ...imagePath])

    const largeImage =
      get(destination, ['image_r', 0, 'sizes', 'gallery-views']) ||
      get(destination, ['image', ['image', 0, 'sizes', 'gallery-views']])

    const itineraryImage = destination?.image?.[0]?.sizes?.['details-hero']

    const taxesAndFees =
      Object.values(pricing)?.[0]?.[0]?.bestRate?.priceInfo?.taxesFees || 0

    return {
      key: `${id}-${sailDate}`,
      description,
      id,
      portName,
      sailDate,
      itineraryImage,
      sailingNights,
      shipName,
      pricing: formatPricing(stateroomTypes, pricing?.[2]),
      image,
      taxesAndFees,
      largeImage,
      shipCode,
      brand,
      destinationCode,
    }
  })
}

const formatPricing = (stateroomTypes, pricing) => {
  if (!pricing?.length || !stateroomTypes?.length) {
    return []
  }

  return stateroomTypes.map(stateRoomData => {
    const { code, description } = stateRoomData
    const stateRoom = pricing.find(
      stateRoom => stateRoom.stateroomType === code,
    )

    if (!stateRoom) {
      return {
        code,
        description,
        price: null,
      }
    }

    const { stateroomDescription, bestRate = {} } = stateRoom
    const { leadPromoDesc, refundability, netPrice, promos } = bestRate
    const perPersonCreditAmount = bestRate.creditAmt / 2
    return {
      code,
      description: stateroomDescription,
      leadPromoDesc,

      perPersonCreditAmount,
      promos: promos || [],
      isRefundable: refundability !== 'DEPOSIT_NOT_REFUNDABLE',
      price: formatPrice(+netPrice / 2),
    }
  })
}

const formatPrice = price => {
  if (price === undefined) {
    return ''
  }

  return Number.isInteger(price) ? price : price.toFixed(2)
}

async function postData(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  })
  if (response.ok) {
    return response.json()
  }
  return Promise.reject(response.json())
}
