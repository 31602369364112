import PropTypes from 'prop-types'
import './footer.scss'
import SocialLinks from '../socialLinks/SocialLinks'

const Footer = ({ content = {}, showSocialLinks = false }) => {
  const { logo, privacyLink, /*termsLink,*/ accolades } = content
  return (
    <footer>
      <div className="accolades">
        {accolades?.length > 0 &&
          accolades.map(accolade => (
            <div key={accolade.img} className="accolade">
              <img
                src={accolade.img}
                alt={accolade.alt}
                className="bold no-text-decoration py-2 px-3 font-heading"
              />
            </div>
          ))}
      </div>
      <SocialLinks shouldShowLinks={showSocialLinks} />
      <div className="logo">{logo?.img && <img src={logo.img} alt="" />}</div>
      <div className="copyright-and-links">
        <div className="copyright">
          <p>{`© ${new Date().getFullYear()} Royal Caribbean International`}</p>
        </div>
        <div className="links">
          {privacyLink?.href && privacyLink?.text && (
            <a
              className="privacy-link"
              href={privacyLink.href}
              target="_blank"
              rel="privacy policy link noreferrer"
            >
              {privacyLink.text}
            </a>
          )}

          {/*termsLink?.href && termsLink?.text && (
            <a
              href={termsLink.href}
              target="_blank"
              rel="Terms of use link noreferrer"
            >
              {termsLink.text}
            </a>
          )*/}
        </div>
      </div>
    </footer>
  )
}
Footer.defaultProps = {
  content: {},
}

Footer.propTypes = {
  content: PropTypes.shape({}),
  showSocialLinks: PropTypes.bool,
}

export default Footer
