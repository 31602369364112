import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTransition, animated, easings } from '@react-spring/web'
import Room from './Room'
import './rooms-block.scss'
import Background from '../background/Background'
import { snakeCase } from 'lodash'

const RoomsBlock = ({ title, height, background, tiles, navTitle }) => {
  const [selectedRoom, setSelectedRoom] = useState(null)

  const selectRoom = index => {
    setSelectedRoom(index)
  }

  const handleBackClick = () => {
    setSelectedRoom(null)
  }

  const getPosition = () => {
    let position = 0
    if (selectedRoom !== null && tiles.length > 0) {
      position = (selectedRoom + 1) / tiles.length
      position *= 100
      position = Math.round(position)
    }
    return position
  }

  const contentTransition = useTransition(tiles[selectedRoom], {
    from: {
      opacity: 0,
      clipPath: `polygon(${getPosition()}% 80%, ${getPosition()}% 80%, ${getPosition()}% 80%, ${getPosition()}% 80%)`,
    },
    enter: {
      opacity: 1,
      clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
    },
    leave: {
      opacity: 0,
      clipPath: 'polygon(50% 100%, 50% 100%, 50% 70%, 50% 70%)',
    },
    config: { duration: 700, easing: easings.easeInOutSine },
  })

  return (
    <section id={navTitle && snakeCase(navTitle)} data-type="RoomsBlock">
      <div className="rooms-block">
        <Background {...background} height={height}>
          <div
            className={`overview ${
              selectedRoom === null ? 'has-selection' : ''
            }`}
          >
            <div className="content">
              <h1>{title}</h1>

              <div className="room-links">
                {tiles.map((stateroomDetail, index) => (
                  <div key={`room-section-${index}`}>
                    <div>
                      <button
                        onClick={() => selectRoom(index)}
                        key={`icon-${index}`}
                      >
                        <img src={stateroomDetail.thumbnail} alt="" />
                      </button>
                    </div>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: stateroomDetail.title,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          {contentTransition((style, room) => (
            <animated.div style={{ ...style }} className="room-wrapper">
              {room && <Room room={room} handleBackClick={handleBackClick} />}
            </animated.div>
          ))}
        </Background>
      </div>
    </section>
  )
}

RoomsBlock.propTypes = {
  background: PropTypes.shape({}),
  height: PropTypes.string,
  navTitle: PropTypes.string,
  tiles: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
}

export default RoomsBlock
