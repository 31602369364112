import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import useElementEnteredScreen from '../../hooks/useElementEnteredScreen'
import './background.scss'
const Background = React.forwardRef(
  (
    {
      image,
      video,
      type,
      focus,
      height,
      color,
      mobileColor,
      backgroundId,
      loopVideo,
      videoVerticalFocus,
      children,
      cinemagraph,
      videoHeight,
    },
    parentVideoRef,
  ) => {
    const ref = useRef(null)
    const videoRef = useRef(null)
    const [videoComplete, setVideoComplete] = useState(false)
    // Root Margin helps normalize different viewport heights as videos weren't playing an shorter screens
    const rootMargin = (1200 - window.visualViewport.height) * 1.2 + 'px'
    const hasEntered = useElementEnteredScreen({
      ref: videoRef,
      threshold: 0.7,
      continueObserving: false,
      rootMargin,
    })

    const calculatedHeight = (height / 100) * 960
    const isMobile = window.innerWidth < 800

    /*
     * The magic number here (0.75) is just a guess at "if the video is at least this much of the block, they probably want it to 'fill' the block"
     * Ultimately this could be replaced with a "Video Fit" dropdown in the CMS if its causing a lot of issues.
     */
    const videoFitHeight =
      isMobile || videoHeight / calculatedHeight > 0.75 ? '100%' : 'auto'

    const backgroundColor = isMobile ? mobileColor : color
    const fadeInImage = (image || cinemagraph) && videoComplete

    useEffect(() => {
      if (hasEntered && videoRef.current) {
        videoRef.current.play()
      }
    }, [hasEntered, videoRef])

    function handleVideoEnded() {
      setVideoComplete(true)
    }

    if (type === 'video') {
      return (
        <div
          ref={ref}
          className="background"
          style={{ height: `${(height / 100) * 960}px` }}
        >
          <video
            playsInline
            ref={ref => {
              videoRef.current = ref

              if (parentVideoRef) {
                parentVideoRef.current = ref
              }
            }}
            muted
            loop={loopVideo}
            style={{ bottom: videoVerticalFocus, height: videoFitHeight }}
            id={backgroundId}
            onEnded={handleVideoEnded}
          >
            <source src={video} type="video/mp4" />
          </video>
          {fadeInImage && (
            <>
              {cinemagraph ? (
                <div
                  className="fade-in-video"
                  style={{
                    height: `${calculatedHeight}px`,
                  }}
                >
                  <video
                    autoPlay
                    playsInline
                    muted
                    loop
                    style={{
                      bottom: videoVerticalFocus,
                      height: videoFitHeight,
                    }}
                    id="cinemagraph"
                  >
                    <source src={cinemagraph} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div
                  className="fade-in-image"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: focus || '50% 50%',
                    height: `${calculatedHeight}px`,
                  }}
                ></div>
              )}
            </>
          )}
          {backgroundColor && (
            <div
              className="gradient-bg"
              style={{ background: backgroundColor }}
            ></div>
          )}
          {children}
        </div>
      )
    }

    return (
      <div
        className="background"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: focus || '50% 50%',
          height: `${calculatedHeight}px`,
        }}
      >
        {backgroundColor && (
          <div
            className="gradient-bg"
            style={{ background: backgroundColor }}
          ></div>
        )}
        {children}
      </div>
    )
  },
)

Background.propTypes = {
  backgroundId: PropTypes.string,
  children: PropTypes.node.isRequired,
  cinemagraph: PropTypes.string,
  color: PropTypes.string,
  focus: PropTypes.string,
  height: PropTypes.any,
  image: PropTypes.string,
  loopVideo: PropTypes.bool,
  mobileColor: PropTypes.string,
  type: PropTypes.string,
  video: PropTypes.string,
  videoHeight: PropTypes.number,
  videoId: PropTypes.string,
  videoVerticalFocus: PropTypes.string,
}

Background.defaultProps = {
  videoId: 'video-id',
  videoVerticalFocus: 0,
}

export default Background
