import { createElement } from 'react'
import { useTransition, animated } from '@react-spring/web'
import { useState } from 'react'
import Home from '../home/Home'

import { SplashIntro, SplashPageVideo } from '../splashPage/SplashPage'
import { useLocation } from 'react-router-dom'

const components = {
  intro: SplashIntro,
  video: SplashPageVideo,
  app: Home,
}
const Routes = props => {
  const { pathname } = useLocation()
  const [activeView, setActiveView] = useState(
    pathname === '/' ? 'video' : 'app',
  )
  const transitions = useTransition(activeView, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 500 },
  })

  const goToVideo = () => setActiveView('video')
  const goToApp = () => setActiveView('app')

  return (
    <div>
      {transitions((style, item) => (
        <animated.div
          style={{
            ...style,
          }}
        >
          {components?.[item] &&
            createElement(components[item], {
              goToVideo,
              goToApp,
              ...props,
            })}
        </animated.div>
      ))}
    </div>
  )
}

export default Routes
