import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '../../components/header/Header'
import Booking from '../../components/booking/Booking'
import Footer from '../../components/footer/Footer'
import BlockConfig from '../../config/blocks'
import { Link } from 'react-router-dom'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './home.scss'
import triggerAnalyticsEvent from '../../utils/triggerAnalyticsEvent'
import { getNextSailings } from '../../api/api'
import { useLocation } from 'react-router-dom'

import backArrow from '../../assets/icons/back-arrow-left.png'
import { snakeCase } from 'lodash/string'

/**
 * TODO:
 * Create visual map of blocks
 * Finish content pop
 * Clean up code / comment magic numbers
 */
const Home = ({
  content,
  brandData,
  canViewBookings,
  isAuthenticated,
  appData,
  agentOverrides,
  setAgentOverrides,
  goToVideo,
  purlData,
  itineraries,
  setItineraries,
}) => {
  const { pathname } = useLocation()
  const [sailings, setSailings] = useState([])
  const [sailingsAreLoading, setSailingsAreLoading] = useState(true)

  const { blocks } = content.pages[pathname]

  const menuItems = Object.keys(content.pages)
    //.sort((a, b) => content.pages[a].menuOrder - content.pages[b].menuOrder)
    .flatMap(path =>
      content.pages[path].blocks
        .filter(block => {
          return !!block?.data?.navTitle
        })
        .map(block => {
          return {
            text: block?.data?.navTitle,
            target: path + '#' + snakeCase(block?.data?.navTitle),
            new: block?.data.flagAsNew,
          }
        }),
    )

  const canViewSailings =
    agentOverrides?.currencyCode === 'USD' ||
    agentOverrides?.currencyCode === 'CAD'

  const searchParams = new URLSearchParams(window.location.search)
  const showSocialLinks = !!(agentOverrides && !isAuthenticated)

  const ssoParam =
    searchParams.get('sso') ||
    searchParams.get('z') ||
    searchParams.get('cptok')

  useEffect(() => {
    getNextSailings({
      currencyCode:
        purlData?.currencyCode || agentOverrides?.currencyCode || '',
    })
      .then(res => {
        setSailings(res)
      })
      .finally(() => {
        setSailingsAreLoading(false)
      })
  }, []) // eslint-disable-line

  function renderBlock({ type, data }, index) {
    const ComponentName = BlockConfig[type]
    return ComponentName ? (
      <ComponentName {...data} key={`${type}-${index}`} />
    ) : null
  }

  function handleCreatePurlSuccess(values) {
    triggerAnalyticsEvent('shareWithClientsIconMicrosite', {
      agencyId: agentOverrides.agencyId,
      agentId: agentOverrides.agentId,
    })
    setAgentOverrides(values)
  }

  function handleLinkClick() {
    window.scroll({ top: 0, behavior: 'instant' })
  }

  return (
    <div
      data-testid="icon-base-app"
      className={`app ${agentOverrides ? 'agent-view' : ''}`}
    >
      <Header
        isAuthenticated={isAuthenticated}
        logo={brandData?.logoDark}
        showBookingLink={canViewBookings && canViewSailings}
        agentData={agentOverrides}
        menuItems={menuItems}
        showSocialLinks={showSocialLinks}
        goToVideo={goToVideo}
        ssoParam={ssoParam}
      />
      <main>
        {pathname !== '/' && (
          <Link
            className="back-to-home"
            to={`/?sso=${ssoParam}`}
            onClick={handleLinkClick}
          >
            <img src={backArrow} className="home-back-arrow" alt="Back" />
            Back to Home
          </Link>
        )}
        {blocks.map((block, i) => renderBlock(block, i))}

        {canViewBookings && (
          <section id="book">
            <Booking
              isAuthenticated={isAuthenticated}
              sailings={sailings}
              agentData={agentOverrides}
              stateOptions={appData?.stateOptions}
              onCreatePurlSuccess={handleCreatePurlSuccess}
              sailingsAreLoading={sailingsAreLoading}
              itineraries={itineraries}
              setItineraries={setItineraries}
            />
          </section>
        )}
      </main>
      <Footer content={content?.footer} showSocialLinks={showSocialLinks} />
    </div>
  )
}

Home.propTypes = {
  agentOverrides: PropTypes.shape({
    agencyId: PropTypes.string,
    agentId: PropTypes.string,
    currencyCode: PropTypes.string,
  }),
  appData: PropTypes.shape({
    stateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  brandData: PropTypes.shape({
    logoDark: PropTypes.string,
  }).isRequired,
  canViewBookings: PropTypes.bool,
  content: PropTypes.shape({
    footer: PropTypes.shape({}),
    pages: PropTypes.shape({}),
  }).isRequired,
  goToVideo: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  itineraries: PropTypes.arrayOf(PropTypes.shape({})),
  purlData: PropTypes.shape({
    currencyCode: PropTypes.string,
  }),
  sailingsAreLoading: PropTypes.bool,
  setAgentOverrides: PropTypes.func,
  setItineraries: PropTypes.func,
}

export default Home
