const encodeKeys = {
  agency: 'a',
  agencyId: 'b',
  currencyCode: 'c',
  agentId: 'd',
  agentEmail: 'e',
  agentName: 'n',
  customMessage: 'm',
  phone: 'p',
}

export default encodeKeys
