import { useEffect, useRef, useState } from 'react'

import FullScreenSection from '../../components/fullScreenSection/FullScreenSection'
import muteIcon from '../../assets/icons/mute.svg'
import unmuteIcon from '../../assets/icons/unmute.svg'
import playIcon from '../../assets/icons/play.svg'
import pauseIcon from '../../assets/icons/pause.svg'
import './splash.scss'
import PropTypes from 'prop-types'

export const SplashPageVideo = ({ content, goToApp }) => {
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(true)
  const [isMute, setIsMute] = useState(true)

  useEffect(() => {
    let currentVideoRef

    if (videoRef.current) {
      currentVideoRef = videoRef.current
      videoRef.current.addEventListener('ended', goToApp)
    }

    return () => {
      if (currentVideoRef) {
        //   remove our event listeners
        currentVideoRef.removeEventListener('ended', goToApp)
      }
    }
  })

  function togglePlaying() {
    if (isPlaying) {
      videoRef.current.pause()
    } else {
      videoRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  function toggleMute() {
    videoRef.current.muted = !isMute
    setIsMute(!isMute)
  }
  return (
    <div className="full-screen-section">
      <video
        playsInline
        autoPlay
        muted
        id="icon-intro"
        poster={content?.splashPage?.video.placeholder}
        ref={videoRef}
      >
        <source src={content?.splashPage?.video.src} type="video/mp4" />
      </video>
      <div className="icon-intro-overlay">
        <div className="icon-intro-actions">
          <div className="icon-intro-cta">
            <button onClick={goToApp}>Skip Video</button>
          </div>
          <div className="icon-intro-media-control">
            {isPlaying ? (
              <button onClick={togglePlaying} aria-label="Pause Video">
                <img src={pauseIcon} alt="Pause Video" />
              </button>
            ) : (
              <button onClick={togglePlaying} aria-label="Play Video">
                <img src={playIcon} alt="Play Video" />
              </button>
            )}
            {isMute ? (
              <button onClick={toggleMute} aria-label="Unmute Video">
                <img src={muteIcon} alt="Unmute Video" />
              </button>
            ) : (
              <button onClick={toggleMute} aria-label="Mute Video">
                <img src={unmuteIcon} alt="Mute Video" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export const SplashIntro = ({ content, goToVideo, goToApp }) => {
  return (
    <FullScreenSection
      backgroundSrc={content?.splashPage?.overlay?.src}
      backgroundPlaceholder={content?.splashPage?.overlay?.placeholder}
      backgroundType="video"
      videoId="splash-overlay"
      focusPoints={content?.splashPage?.overlay?.backgroundPosition}
    >
      <div
        className="splash-intro"
        style={{
          backgroundColor: content?.splashPage?.overlay?.backgroundColor,
        }}
      >
        <div className="splash-intro-content">
          {content?.splashPage?.icon && (
            <img className="icon" src={content.splashPage.icon} alt="" />
          )}
          {content?.splashPage?.subTitle && (
            <p>{content.splashPage.subTitle}</p>
          )}
          {content?.splashPage?.title && <h1>{content.splashPage.title}</h1>}
          <div className="splash-ctas">
            <div className="splash-cta">
              <button className="accept" onClick={goToVideo}>
                Let's Go
              </button>
            </div>
            <div className="splash-cta">
              <button className="deny" onClick={goToApp}>
                Skip Video
              </button>
            </div>
          </div>
        </div>
      </div>
    </FullScreenSection>
  )
}

const propTypes = {
  content: PropTypes.shape({
    splashPage: PropTypes.shape({
      overlay: PropTypes.shape({}),
    }),
  }),
  goToVideo: PropTypes.func,
  goToApp: PropTypes.func,
}
SplashIntro.propTypes = {
  ...propTypes,
}

SplashPageVideo.propTypes = {
  ...propTypes,
}
