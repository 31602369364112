import PropTypes from 'prop-types'
import { useState } from 'react'
import dayjs from 'dayjs'
import Sailings from '../sailings/Sailings'
import Modal from '../modal/Modal'
import BookingForm from '../bookingForm/BookingForm'
import bookingLogo from '../../assets/icons/booking-logo.svg'

import './booking.scss'
import AgentDetails from './AgentDetails'
import LoadingSpinner from '../loadingSpinner/LoadingSpinner'
import { fetchItinerary } from '../../api/api'
import ItineraryTable from './ItineraryTable'

const formatItinerary = ({ days, sailDate }) => {
  if (!days.length) {
    return []
  }
  return days.map(activities => {
    const {
      activity,
      day,
      name,
      arrivalTime,
      departureTime,
      isFeatured,
      highlight,
    } = activities
    const displayDate = dayjs(sailDate)
      .add(day - 1, 'day')
      .format('MMMM D, YYYY')

    return {
      displayDate,
      activity: activity === 'CRUISING' ? 'At Sea' : activity,
      name,
      arrivalTime: arrivalTime
        ? dayjs(`${displayDate} ${arrivalTime}`).format('h:mm a')
        : '',
      departureTime: departureTime
        ? dayjs(`${displayDate} ${departureTime}`).format('h:mm a')
        : '',
      isFeatured,
      featuredImage: highlight?.image?.[0]?.sizes?.['details-hero'],
    }
  })
}

const Booking = ({
  sailings,
  agentData,
  stateOptions,
  isAuthenticated,
  onCreatePurlSuccess,
  sailingsAreLoading,
  itineraries,
  setItineraries,
}) => {
  const [bookingModalState, setBookingModalState] = useState({
    sailing: null,
    isOpen: false,
  })

  const canViewSailings =
    agentData?.currencyCode === 'USD' || agentData?.currencyCode === 'CAD'
  const [activeItineraryState, setActiveItineraryState] = useState({
    loading: false,
    open: false,
    id: null,
  })

  const activeItinerary = itineraries?.[activeItineraryState?.id]
  const handleOpenItineraryModal = ({ id, sailDate, key, img }) => {
    if (!itineraries?.[key]) {
      // https://rccl-ips-stg1.verbinteractive.com/api/rcl/cruise/FR3BH035/itinerary?brand=R&sailDate=2022-10-21
      setActiveItineraryState({
        open: true,
        loading: true,
        id: key,
      })

      return fetchItinerary({ id, sailDate })
        .then(res => {
          setItineraries({
            ...itineraries,
            [key]: {
              days: formatItinerary({ days: res?.package, sailDate }),
              img,
            },
          })
          setActiveItineraryState({
            ...activeItineraryState,
            id: key,
            open: true,
            loading: false,
          })
        })
        .catch(err => {
          console.log(err)
        })
    } else {
      setActiveItineraryState({
        open: true,
        loading: false,
        id: key,
      })
    }
  }

  const handleCloseItineraryModal = () => {
    setActiveItineraryState({
      loading: false,
      open: false,
      id: null,
    })
  }

  const onBookingButtonClick = sailing => {
    setBookingModalState({
      sailing,
      isOpen: true,
    })
  }

  const closeBookingModal = () =>
    setBookingModalState({
      sailing: null,
      isOpen: false,
    })
  return (
    <div className="booking">
      <div className="booking-container">
        <div
          className={`booking-agent ${isAuthenticated ? 'authenticated' : ''}`}
        >
          <img src={bookingLogo} alt="" />
          {isAuthenticated && (
            <>
              <h1 className="remove-margin uppercase booking-title">
                Share With Your Clients
              </h1>
              <p className="agent-instruction">
                Generate your personalized URL to share Icon of the Seas with
                your clients
              </p>
            </>
          )}
          {agentData && (
            <>
              {!isAuthenticated && (
                <>
                  <h1 className="remove-margin uppercase booking-title">
                    Start Planning Your Vacation
                  </h1>
                  <p>Contact me now to start planning your iconic vacation!</p>
                </>
              )}
              <div className="grey-line" />
              <AgentDetails
                agentData={agentData}
                isAuthenticated={isAuthenticated}
                onCreatePurlSuccess={onCreatePurlSuccess}
              />
              {!isAuthenticated && agentData?.customMessage && (
                <div>
                  <p className="text-left custom-message">
                    {agentData?.customMessage}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
        {!isAuthenticated && canViewSailings && (
          <div className="booking-sailing">
            <div className="booking-sailing-title">
              <h1>Select a departure date</h1>
            </div>
            {sailingsAreLoading ? (
              <LoadingSpinner color="white" />
            ) : (
              <>
                {sailings?.length > 0 ? (
                  <Sailings
                    currencyCode={agentData?.currencyCode}
                    sailings={sailings}
                    handleRequestClick={onBookingButtonClick}
                    handleOpenItineraryModal={handleOpenItineraryModal}
                  />
                ) : (
                  <p>No Sailings found. </p>
                )}
              </>
            )}
          </div>
        )}

        <Modal
          isOpen={bookingModalState.isOpen}
          onRequestClose={closeBookingModal}
        >
          <BookingForm
            stateOptions={stateOptions}
            stateRoomOptions={
              bookingModalState?.sailing?.pricing
                ?.filter(({ price }) => !!price)
                ?.map(({ code, description }) => ({
                  value: code,
                  label: description,
                })) || []
            }
            sailing={bookingModalState?.sailing}
            agentData={agentData}
            dismissModal={closeBookingModal}
          />
        </Modal>
      </div>
      <Modal
        isOpen={activeItineraryState?.open}
        onRequestClose={handleCloseItineraryModal}
      >
        <div className="booking-itinerary-modal">
          {activeItineraryState?.loading ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              {activeItinerary && (
                <div>
                  <button
                    className="close-button"
                    onClick={handleCloseItineraryModal}
                  >
                    close X
                  </button>
                  <ItineraryTable
                    days={activeItinerary?.days || []}
                    image={activeItinerary?.img}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    </div>
  )
}

Booking.defaultProps = {
  sailings: null,
  agentData: null,
  isAuthenticated: false,
}

Booking.propTypes = {
  agentData: PropTypes.shape({
    agency: PropTypes.string,
    agentEmail: PropTypes.string,
    agentName: PropTypes.string,
    currencyCode: PropTypes.string,
    customMessage: PropTypes.string,
    phone: PropTypes.string,
  }),
  isAuthenticated: PropTypes.bool,
  onCreatePurlSuccess: PropTypes.func.isRequired,
  sailings: PropTypes.arrayOf(PropTypes.shape({})),
  stateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  sailingsAreLoading: PropTypes.bool,
  itineraries: PropTypes.arrayOf(PropTypes.shape({})),
  setItineraries: PropTypes.func,
}

export default Booking
