import PropTypes from 'prop-types'

import './gradient-text.scss'

const GradientText = ({ type, color, children }) => {
  const Tag = type
  const isGradient = color?.indexOf('gradient') > -1

  return (
    <>
      {isGradient ? (
        <Tag
          className="gradient-text-tag"
          style={{
            display: 'inline-block',
            background: color,
            // WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {children}
        </Tag>
      ) : (
        <Tag
          style={{
            color,
          }}
        >
          {children}
        </Tag>
      )}
    </>
  )
}

GradientText.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default GradientText
